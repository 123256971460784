/* ========================================================================
 * Apricot's Slider
 * ========================================================================
 *
 * This plugin has been written based on
 * https://github.com/leongersen/noUiSlider
 * ======================================================================== */

// SCSS
import "../scss/includes/apricot-base.scss";
import "../scss/includes/slider.scss";

// javaScript
import Utils from "./CBUtils";

/**
 * Slider
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Element} data.input1
 * @param {Element} data.input2
 * @param {String|Array} data.ariaControls
 * @param {Boolean} data.handleInputChange
 * @param {Number|Array} data.start
 * @param {String|Array} data.label
 * @param {Object} data.range
 * @param {Number} data.range.min
 * @param {Number} data.range.max
 * @param {Boolean} data.animation
 * @param {String} data.behaviour
 * @param {Number} data.step
 * @param {Number} data.keyboardDefaultStep
 * @param {Number} data.keyboardPageMultiplier
 * @param {Object} data.format
 * @param {String|Number} data.format.to //encode
 * @param {String|Number} data.format.from // decode
 * @param {Number} data.format.input // input
 * @param {Object} data.ariaFormat
 * @param {String|Number} data.ariaFormat.to
 * @param {String|Number} data.ariaFormat.from
 * @param {Number} data.margin
 * @param {Number} data.limit
 * @param {Number} data.padding
 * @param {Boolean|Object} data.tooltips
 * @param {String|Number} data.tooltips.to
 * @param {String|Number} data.tooltips.from
 * @param {Boolean} data.tooltips.sticky
 * @param {Object} data.pips
 * @param {String} data.pips.mode
 * @param {Number|Array} data.pips.values
 * @param {Boolean} data.pips.stepped
 * @param {Number} data.pips.density
 * @param {Object} data.pips.format
 * @param {String|Number} data.pips.format.to
 * @param {String|Number} data.pips.format.from
 * @param {Number} data.pips.filter
 * @returns {{init: Function}}
 */

const Slider = (() => {
  // ------------------------ SPECTRUM
  class Spectrum {
    xPct = [];
    xVal = [];
    xSteps = [];
    xNumSteps = [];
    xHighestCompleteStep = [];
    snap;

    constructor(entry, snap, singleStep) {
      this.xSteps = [singleStep || false];
      this.xNumSteps = [false];

      this.snap = snap;

      let index;
      const ordered = [];

      // Map the object keys to an array.
      Object.keys(entry).forEach((index) => {
        ordered.push([asArray(entry[index]), index]);
      });

      // Sort all entries by value (numeric sort).
      ordered.sort((a, b) => {
        return a[0][0] - b[0][0];
      });

      // Convert all entries to subranges.
      for (index = 0; index < ordered.length; index++) {
        this.handleEntryPoint(ordered[index][1], ordered[index][0]);
      }

      // Store the actual step values.
      // xSteps is sorted in the same order as xPct and xVal.
      this.xNumSteps = this.xSteps.slice(0);

      // Convert all numeric steps to the percentage of the subrange they represent.
      for (index = 0; index < this.xNumSteps.length; index++) {
        this.handleStepPoint(index, this.xNumSteps[index]);
      }
    }
    // public
    getDistance(value) {
      const distances = [];

      for (let index = 0; index < this.xNumSteps.length - 1; index++) {
        distances[index] = fromPercentage(this.xVal, value, index);
      }

      return distances;
    }

    // Calculate the percentual distance over the whole scale of ranges.
    // direction: 0 = backwards / 1 = forwards
    // public
    getAbsoluteDistance(value, distances, direction) {
      let xPct_index = 0;

      // Calculate range where to start calculation
      if (value < this.xPct[this.xPct.length - 1]) {
        while (value > this.xPct[xPct_index + 1]) {
          xPct_index++;
        }
      } else if (value === this.xPct[this.xPct.length - 1]) {
        xPct_index = this.xPct.length - 2;
      }

      // If looking backwards and the value is exactly at a range separator then look one range further
      if (!direction && value === this.xPct[xPct_index + 1]) {
        xPct_index++;
      }

      if (distances === null) {
        distances = [];
      }

      let start_factor;
      let rest_factor = 1;

      let rest_rel_distance = distances[xPct_index];

      let range_pct = 0;

      let rel_range_distance = 0;
      let abs_distance_counter = 0;
      let range_counter = 0;

      // Calculate what part of the start range the value is
      if (direction) {
        start_factor =
          (value - this.xPct[xPct_index]) / (this.xPct[xPct_index + 1] - this.xPct[xPct_index]);
      } else {
        start_factor =
          (this.xPct[xPct_index + 1] - value) / (this.xPct[xPct_index + 1] - this.xPct[xPct_index]);
      }

      // Do until the complete distance across ranges is calculated
      while (rest_rel_distance > 0) {
        // Calculate the percentage of total range
        range_pct =
          this.xPct[xPct_index + 1 + range_counter] - this.xPct[xPct_index + range_counter];

        // Detect if the margin, padding or limit is larger then the current range and calculate
        if (distances[xPct_index + range_counter] * rest_factor + 100 - start_factor * 100 > 100) {
          // If larger then take the percentual distance of the whole range
          rel_range_distance = range_pct * start_factor;
          // Rest factor of relative percentual distance still to be calculated
          rest_factor =
            (rest_rel_distance - 100 * start_factor) / distances[xPct_index + range_counter];
          // Set start factor to 1 as for next range it does not apply.
          start_factor = 1;
        } else {
          // If smaller or equal then take the percentual distance of the calculate percentual part of that range
          rel_range_distance =
            ((distances[xPct_index + range_counter] * range_pct) / 100) * rest_factor;
          // No rest left as the rest fits in current range
          rest_factor = 0;
        }

        if (direction) {
          abs_distance_counter = abs_distance_counter - rel_range_distance;
          // Limit range to first range when distance becomes outside of minimum range
          if (this.xPct.length + range_counter >= 1) {
            range_counter--;
          }
        } else {
          abs_distance_counter = abs_distance_counter + rel_range_distance;
          // Limit range to last range when distance becomes outside of maximum range
          if (this.xPct.length - range_counter >= 1) {
            range_counter++;
          }
        }

        // Rest of relative percentual distance still to be calculated
        rest_rel_distance = distances[xPct_index + range_counter] * rest_factor;
      }

      return value + abs_distance_counter;
    }
    // public
    toStepping(value) {
      value = toStepping(this.xVal, this.xPct, value);

      return value;
    }

    // public
    fromStepping(value) {
      return fromStepping(this.xVal, this.xPct, value);
    }
    // public
    getStep(value) {
      value = getStep(this.xPct, this.xSteps, this.snap, value);

      return value;
    }

    // public
    getDefaultStep(value, isDown, size) {
      let j = getJ(value, this.xPct);

      // When at the top or stepping down, look at the previous sub-range
      if (value === 100 || (isDown && value === this.xPct[j - 1])) {
        j = Math.max(j - 1, 1);
      }
      return (this.xVal[j] - this.xVal[j - 1]) / size;
    }

    // public
    getNearbySteps(value) {
      const j = getJ(value, this.xPct);

      return {
        stepBefore: {
          startValue: this.xVal[j - 2],
          step: this.xNumSteps[j - 2],
          highestStep: this.xHighestCompleteStep[j - 2],
        },
        thisStep: {
          startValue: this.xVal[j - 1],
          step: this.xNumSteps[j - 1],
          highestStep: this.xHighestCompleteStep[j - 1],
        },
        stepAfter: {
          startValue: this.xVal[j],
          step: this.xNumSteps[j],
          highestStep: this.xHighestCompleteStep[j],
        },
      };
    }

    // public
    countStepDecimals() {
      const stepDecimals = this.xNumSteps.map(countDecimals);
      return Math.max.apply(null, stepDecimals);
    }
    // mas
    // public
    hasNoSize() {
      return this.xVal[0] === this.xVal[this.xVal.length - 1];
    }

    // Outside testing
    // public
    convert(value) {
      return this.getStep(this.toStepping(value));
    }

    // privates
    handleEntryPoint(index, value) {
      let percentage;

      // Covert min/max syntax to 0 and 100.
      if (index === "min") {
        percentage = 0;
      } else if (index === "max") {
        percentage = 100;
      } else {
        percentage = parseFloat(index);
      }

      // Check for correct input.
      if (!isNumeric(percentage) || !isNumeric(value[0])) {
        throw new Error("Slider: 'range' value isn't numeric.");
      }

      // Store values.
      this.xPct.push(percentage);
      this.xVal.push(value[0]);

      const value1 = Number(value[1]);

      // NaN will evaluate to false too, but to keep
      // logging clear, set step explicitly. Make sure
      // not to override the 'step' setting with false.
      if (!percentage) {
        if (!isNaN(value1)) {
          this.xSteps[0] = value1;
        }
      } else {
        this.xSteps.push(isNaN(value1) ? false : value1);
      }

      this.xHighestCompleteStep.push(0);
    }

    // private
    handleStepPoint(i, n) {
      // Ignore 'false' stepping.
      if (!n) {
        return;
      }

      // Step over zero-length ranges
      if (this.xVal[i] === this.xVal[i + 1]) {
        this.xSteps[i] = this.xHighestCompleteStep[i] = this.xVal[i];

        return;
      }

      // Factor to range ratio
      this.xSteps[i] =
        fromPercentage([this.xVal[i], this.xVal[i + 1]], n, 0) /
        subRangeRatio(this.xPct[i], this.xPct[i + 1]);

      const totalSteps = (this.xVal[i + 1] - this.xVal[i]) / this.xNumSteps[i];
      const highestStep = Math.ceil(Number(totalSteps.toFixed(3)) - 1);
      const step = this.xVal[i] + this.xNumSteps[i] * highestStep;

      this.xHighestCompleteStep[i] = step;
    }
  }

  // ------------------------ END SPECTRUM

  // ------------------------ HELPER METHODS
  const isValidFormatter = (entry) => {
    return isValidPartialFormatter(entry) && typeof entry.from === "function";
  };

  const isValidPartialFormatter = (entry) => {
    // partial formatters only need a to function and not a from function
    return (
      typeof entry === "object" &&
      (typeof entry.to === "function" || typeof entry.sticky === "boolean")
    );
  };

  const isSet = (value) => {
    return value !== null && value !== undefined;
  };

  // Bindable version
  const preventDefault = (e) => {
    e.preventDefault();
  };

  // Removes duplicates from an array.
  const unique = (array) => {
    return array.filter(function (a) {
      return !this[a] ? (this[a] = true) : false;
    }, {});
  };

  // Round a value to the closest 'to'.
  const closest = (value, to) => {
    return Math.round(value / to) * to;
  };

  // Current position of an element relative to the document.
  const offset = (elem, orientation) => {
    const rect = elem.getBoundingClientRect();
    const doc = elem.ownerDocument;
    const docElem = doc.documentElement;
    const pageOffset = getPageOffset(doc);

    if (/webkit.*Chrome.*Mobile/i.test(navigator.userAgent)) {
      pageOffset.x = 0;
    }

    return orientation
      ? rect.top + pageOffset.y - docElem.clientTop
      : rect.left + pageOffset.x - docElem.clientLeft;
  };

  // Checks whether a value is numerical.
  const isNumeric = (a) => {
    return typeof a === "number" && !isNaN(a) && isFinite(a);
  };

  // Sets a class and removes it after [duration] ms.
  const addClassFor = (element, className, duration) => {
    if (duration > 0) {
      Utils.addClass(element, className);
      setTimeout(() => {
        Utils.removeClass(element, className);
      }, duration);
    }
  };

  // Limits a value to 0 - 100
  const limit = (a) => {
    return Math.max(Math.min(a, 100), 0);
  };

  // Wraps a variable as an array, if it isn't one yet.
  // Note that an input array is returned by reference!
  const asArray = (a) => {
    return Array.isArray(a) ? a : [a];
  };

  // Counts decimals
  const countDecimals = (numStr) => {
    numStr = String(numStr);
    const pieces = numStr.split(".");
    return pieces.length > 1 ? pieces[1].length : 0;
  };

  // https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollY#Notes
  const getPageOffset = (doc) => {
    const supportPageOffset = window.pageXOffset !== undefined;
    const isCSS1Compat = (doc.compatMode || "") === "CSS1Compat";
    const x = supportPageOffset
      ? window.pageXOffset
      : isCSS1Compat
      ? doc.documentElement.scrollLeft
      : doc.body.scrollLeft;
    const y = supportPageOffset
      ? window.pageYOffset
      : isCSS1Compat
      ? doc.documentElement.scrollTop
      : doc.body.scrollTop;

    return {
      x: x,
      y: y,
    };
  };

  // we provide a function to compute constants instead
  // of accessing window.* as soon as the module needs it
  // so that we do not compute anything if not needed
  const getActions = () => {
    // Determine the events to bind. IE11 implements pointerEvents without
    // a prefix, which breaks compatibility with the IE10 implementation.
    return window.navigator.pointerEnabled
      ? {
          start: "pointerdown",
          move: "pointermove",
          end: "pointerup",
        }
      : window.navigator.msPointerEnabled
      ? {
          start: "MSPointerDown",
          move: "MSPointerMove",
          end: "MSPointerUp",
        }
      : {
          start: "mousedown touchstart",
          move: "mousemove touchmove",
          end: "mouseup touchend",
        };
  };

  const getSupportsPassive = () => {
    let supportsPassive = false;

    try {
      const opts = Object.defineProperty({}, "passive", {
        get: () => {
          supportsPassive = true;
        },
      });

      // @ts-ignore
      window.addEventListener("test", null, opts);
    } catch (e) {}
    /* eslint-enable */

    return supportsPassive;
  };

  const getSupportsTouchActionNone = () => {
    return window.CSS && CSS.supports && CSS.supports("touch-action", "none");
  };

  // Determine the size of a sub-range in relation to a full range.
  const subRangeRatio = (pa, pb) => {
    return 100 / (pb - pa);
  };

  // (percentage) How many percent is this value of this range?
  const fromPercentage = (range, value, startRange) => {
    return (value * 100) / (range[startRange + 1] - range[startRange]);
  };

  // (percentage) Where is this value on this range?
  const toPercentage = (range, value) => {
    return fromPercentage(range, range[0] < 0 ? value + Math.abs(range[0]) : value - range[0], 0);
  };

  // (value) How much is this percentage on this range?
  const isPercentage = (range, value) => {
    return (value * (range[1] - range[0])) / 100 + range[0];
  };

  const getJ = (value, arr) => {
    let j = 1;

    while (value >= arr[j]) {
      j += 1;
    }

    return j;
  };

  // (percentage) Input a value, find where, on a scale of 0-100, it applies.
  const toStepping = (xVal, xPct, value) => {
    if (value >= xVal.slice(-1)[0]) {
      return 100;
    }

    const j = getJ(value, xVal);
    const va = xVal[j - 1];
    const vb = xVal[j];
    const pa = xPct[j - 1];
    const pb = xPct[j];

    return pa + toPercentage([va, vb], value) / subRangeRatio(pa, pb);
  };

  // (value) Input a percentage, find where it is on the specified range.
  const fromStepping = (xVal, xPct, value) => {
    // There is no range group that fits 100
    if (value >= 100) {
      return xVal.slice(-1)[0];
    }

    const j = getJ(value, xPct);
    const va = xVal[j - 1];
    const vb = xVal[j];
    const pa = xPct[j - 1];
    const pb = xPct[j];

    return isPercentage([va, vb], (value - pa) * subRangeRatio(pa, pb));
  };

  // (percentage) Get the step that applies at a certain value.
  const getStep = (xPct, xSteps, snap, value) => {
    if (value === 100) {
      return value;
    }

    const j = getJ(value, xPct);
    const a = xPct[j - 1];
    const b = xPct[j];

    // If 'snap' is set, steps are used as fixed points on the slider.
    if (snap) {
      // Find the closest position, a or b.
      if (value - a > (b - a) / 2) {
        return b;
      }

      return a;
    }

    if (!xSteps[j - 1]) {
      return value;
    }

    return xPct[j - 1] + closest(value - xPct[j - 1], xSteps[j - 1]);
  };

  // ------------------------ END HELPER METHODS

  // ------------------------ OPTIONS
  const defaultFormatter = {
    to: (value) => {
      return value === undefined ? "" : value.toFixed(2);
    },
    from: Number,
  };

  // Namespaces of internal event listeners
  const INTERNAL_EVENT_NS = {
    tooltips: ".__tooltips",
    aria: ".__aria",
  };

  const testStep = (parsed, entry) => {
    if (!isNumeric(entry)) {
      throw new Error("Slider: 'step' is not numeric.");
    }

    // The step option can still be used to set stepping
    // for linear sliders. Overwritten if set in 'range'.
    parsed.singleStep = entry;
  };

  const testKeyboardPageMultiplier = (parsed, entry) => {
    if (!isNumeric(entry)) {
      throw new Error("Slider: 'keyboardPageMultiplier' is not numeric.");
    }

    parsed.keyboardPageMultiplier = entry;
  };

  const testKeyboardDefaultStep = (parsed, entry) => {
    if (!isNumeric(entry)) {
      throw new Error("Slider: 'keyboardDefaultStep' is not numeric.");
    }

    parsed.keyboardDefaultStep = entry;
  };

  const testRange = (parsed, entry) => {
    // Filter incorrect input.
    if (typeof entry !== "object" || Array.isArray(entry)) {
      throw new Error("Slider: 'range' is not an object.");
    }

    // Catch missing start or end.
    if (entry.min === undefined || entry.max === undefined) {
      throw new Error("Slider: Missing 'min' or 'max' in 'range'.");
    }

    parsed.spectrum = new Spectrum(entry, parsed.snap || false, parsed.singleStep);
  };

  const testStart = (parsed, entry) => {
    entry = asArray(entry);

    // Validate input. Values aren't tested, as the public .val method
    // will always provide a valid location.
    if (!Array.isArray(entry) || !entry.length) {
      throw new Error("Slider: 'start' option is incorrect.");
    }

    // Store the number of handles.
    parsed.handles = entry.length;

    // When the slider is initialized, the .val method will
    // be called with the start options.
    parsed.start = entry;
  };

  const testLabel = (parsed, entry) => {
    entry = asArray(entry);

    // Validate input. Values aren't tested, as the public .val method
    // will always provide a valid location.
    if (!Array.isArray(entry) || !entry.length) {
      throw new Error("Slider: 'label' option is incorrect.");
    }

    if (parsed.handles > 1 && entry.length === 1) {
      entry.push(entry[0]);
    }
    parsed.label = entry;
  };

  const testSnap = (parsed, entry) => {
    if (typeof entry !== "boolean") {
      throw new Error("Slider: 'snap' option must be a boolean.");
    }

    // Enforce 100% stepping within subranges.
    parsed.snap = entry;
  };

  const testConnect = (parsed, entry) => {
    let connect = [];

    if (Array.isArray(parsed.start) && parsed.start.length > 1) {
      connect = [false, true, false];
    } else {
      connect = [true, false];
    }

    parsed.connect = connect;
  };

  const testMargin = (parsed, entry) => {
    if (!isNumeric(entry)) {
      throw new Error("Slider: 'margin' option must be numeric.");
    }

    if (entry === 0) {
      return;
    }

    parsed.margin = parsed.spectrum.getDistance(entry);
  };

  const testLimit = (parsed, entry) => {
    if (!isNumeric(entry)) {
      throw new Error("Slider: 'limit' option must be numeric.");
    }

    parsed.limit = parsed.spectrum.getDistance(entry);

    if (!parsed.limit || parsed.handles < 2) {
      throw new Error(
        "Slider: 'limit' option is only supported on linear sliders with 2 or more handles."
      );
    }
  };

  const testPadding = (parsed, entry) => {
    let index;

    if (!isNumeric(entry) && !Array.isArray(entry)) {
      throw new Error("Slider: 'padding' option must be numeric or array of exactly 2 numbers.");
    }

    if (
      Array.isArray(entry) &&
      !(entry.length === 2 || isNumeric(entry[0]) || isNumeric(entry[1]))
    ) {
      throw new Error("Slider: 'padding' option must be numeric or array of exactly 2 numbers.");
    }

    if (entry === 0) {
      return;
    }

    if (!Array.isArray(entry)) {
      entry = [entry, entry];
    }

    // 'getDistance' returns false for invalid values.
    parsed.padding = [parsed.spectrum.getDistance(entry[0]), parsed.spectrum.getDistance(entry[1])];

    for (index = 0; index < parsed.spectrum.xNumSteps.length - 1; index++) {
      // last "range" can't contain step size as it is purely an endpoint.
      if (parsed.padding[0][index] < 0 || parsed.padding[1][index] < 0) {
        throw new Error("Slider: 'padding' option must be a positive number(s).");
      }
    }

    const totalPadding = entry[0] + entry[1];
    const firstValue = parsed.spectrum.xVal[0];
    const lastValue = parsed.spectrum.xVal[parsed.spectrum.xVal.length - 1];

    if (totalPadding / (lastValue - firstValue) > 1) {
      throw new Error("Slider: 'padding' option must not exceed 100% of the range.");
    }
  };

  const testBehaviour = (parsed, entry) => {
    // Make sure the input is a string.
    if (typeof entry !== "string") {
      throw new Error("Slider: 'behaviour' must be a string containing options.");
    }

    // Check if the string contains any keywords.
    // None are required.
    const tap = entry.indexOf("tap") >= 0;
    const snap = entry.indexOf("snap") >= 0;

    parsed.events = {
      tap: tap || snap,
      snap: snap,
    };
  };

  const testTooltips = (parsed, entry) => {
    if (entry === false) {
      return;
    }

    if (entry === true || isValidPartialFormatter(entry)) {
      parsed.tooltips = [];

      for (let i = 0; i < parsed.handles; i++) {
        parsed.tooltips.push(entry);
      }
    } else {
      entry = asArray(entry);

      if (entry.length !== parsed.handles) {
        throw new Error("Slider: must pass a formatter for all handles.");
      }

      entry.forEach((formatter) => {
        if (typeof formatter !== "boolean" && !isValidPartialFormatter(formatter)) {
          throw new Error("Slider: 'tooltips' must be passed a formatter or 'false'.");
        }
      });

      parsed.tooltips = entry;
    }
  };
  const testHandleInputChange = (parsed, entry) => {
    parsed.handleInputChange = entry;
  };
  const testAnimation = (parsed, entry) => {
    parsed.animation = entry;
  };
  const testAriaFormat = (parsed, entry) => {
    if (!isValidPartialFormatter(entry)) {
      throw new Error("Slider: 'ariaFormat' requires 'to' method.");
    }

    parsed.ariaFormat = entry;
  };

  const testFormat = (parsed, entry) => {
    if (!isValidFormatter(entry)) {
      throw new Error("Slider: 'format' requires 'to' and 'from' methods.");
    }

    parsed.format = entry;
  };

  const testDocumentElement = (parsed, entry) => {
    // This is an advanced option. Passed values are used without validation.
    parsed.documentElement = entry;
  };

  // Test all developer settings and parse to assumption-safe values.
  const testOptions = (options) => {
    const parsed = {
      margin: null,
      limit: null,
      padding: null,
      ariaFormat: defaultFormatter,
      format: defaultFormatter,
    };

    // Tests are executed in the order they are presented here.
    const tests = {
      step: { r: false, t: testStep },
      keyboardPageMultiplier: { r: false, t: testKeyboardPageMultiplier },
      keyboardDefaultStep: { r: false, t: testKeyboardDefaultStep },
      start: { r: true, t: testStart },
      label: { r: true, t: testLabel },
      connect: { r: true, t: testConnect },
      snap: { r: false, t: testSnap },
      range: { r: true, t: testRange },
      margin: { r: false, t: testMargin },
      limit: { r: false, t: testLimit },
      padding: { r: false, t: testPadding },
      behaviour: { r: true, t: testBehaviour },
      ariaFormat: { r: false, t: testAriaFormat },
      format: { r: false, t: testFormat },
      tooltips: { r: false, t: testTooltips },
      animation: { r: false, t: testAnimation },
      handleInputChange: { r: false, t: testHandleInputChange },
      documentElement: { r: false, t: testDocumentElement },
    };

    const defaults = {
      connect: false,
      direction: "ltr",
      behaviour: "tap",
      step: 1,
      keyboardDefaultStep: 1,
      keyboardPageMultiplier: 5,
    };

    // label defaults to generic if any.
    if (!options.label) {
      const entry = asArray(options.start);
      if (!Array.isArray(entry) || !entry.length) {
        throw new Error("Slider: 'start' option is incorrect.");
      }
      if (entry.length > 1) {
        options.label = ["min slider handle", "max slider handle"];
      } else {
        options.label = "slider handle";
      }
    }

    // AriaFormat defaults to regular format, if any.
    if (options.format && !options.ariaFormat) {
      options.ariaFormat = options.format;
    }

    if (!options.hasOwnProperty("handleInputChange")) {
      options.handleInputChange = true;
    }

    if (!options.hasOwnProperty("animation")) {
      options.animation = true;
    }

    // Run all options through a testing mechanism to ensure correct
    // input. It should be noted that options might get modified to
    // be handled properly. E.g. wrapping integers in arrays.
    Object.keys(tests).forEach((name) => {
      // If the option isn't set, but it is required, throw an error.
      if (!isSet(options[name]) && defaults[name] === undefined) {
        if (tests[name].r) {
          throw new Error("Slider: '" + name + "' is required.");
        }

        return;
      }

      tests[name].t(parsed, !isSet(options[name]) ? defaults[name] : options[name]);
    });

    // Forward pips options
    parsed.pips = options.pips;

    parsed.transformRule = "transform";
    parsed.style = "left";

    return parsed;
  };

  // ------------------------ END OPTIONS

  const scope = (target, options, originalOptions) => {
    const actions = getActions();
    const supportsTouchActionNone = getSupportsTouchActionNone();
    const supportsPassive = supportsTouchActionNone && getSupportsPassive();

    // All variables local to 'scope' are prefixed with 'scope_'

    // Slider DOM Nodes
    const scope_Target = target;
    let scope_Base;
    let scope_Handles;
    let scope_Connects;
    let scope_Pips;
    let scope_Tooltips;

    // Slider state values
    let scope_Spectrum = options.spectrum;
    const scope_Values = [];
    let scope_Locations = [];
    const scope_HandleNumbers = [];
    let scope_ActiveHandlesCount = 0;
    const scope_Events = {};

    // Document Nodes
    const scope_Document = target.ownerDocument;
    const scope_DocumentElement = options.documentElement || scope_Document.documentElement;
    const scope_Body = scope_Document.body;
    const scope_DirOffset = 100;

    // Creates a node, adds it to target, returns the new node.
    const addNodeTo = (addTarget, className) => {
      const div = scope_Document.createElement("div");
      if (className) {
        Utils.addClass(div, className);
      }

      addTarget.appendChild(div);

      return div;
    };

    // Append a origin to the base
    const addOrigin = (base, handleNumber) => {
      const origin = addNodeTo(base, "cb-slider-origin");
      const handle = addNodeTo(origin, "cb-slider-handle");

      addNodeTo(handle, "cb-slider-touch-area");

      handle.setAttribute("data-handle", String(handleNumber));
      handle.setAttribute("aria-label", options.label[handleNumber]);

      handle.setAttribute("tabindex", "0");
      handle.addEventListener("keydown", (event) => {
        return eventKeydown(event, handleNumber);
      });

      handle.setAttribute("role", "slider");
      handle.setAttribute("aria-orientation", "horizontal");
      if (isSliderDisabled()) {
        Utils.attr(handle, "aria-disabled", "true");
        Utils.attr(handle, "tabindex", "-1");
      }

      const elemId = Utils.attr(originalOptions.elem, "id");
      if (handleNumber === 0) {
        Utils.addClass(handle, "cb-slider-handle-lower");
        Utils.attr(handle, "id", `${elemId}_handle1`);
      } else if (handleNumber === options.handles - 1) {
        Utils.addClass(handle, "cb-slider-handle-upper");
        Utils.attr(handle, "id", `${elemId}_handle2`);
      }

      // A11Y

      if (handleNumber === 0) {
        if (originalOptions.input1) {
          const inputID1 =
            Utils.attr(originalOptions.input1, "id") || Utils.uniqueID(5, "apricot_");
          originalOptions.input1.setAttribute("id", inputID1);
          Utils.attr(handle, "aria-controls", inputID1);
          Utils.attr(originalOptions.input1, "aria-controls", `${elemId}_handle1`);

          const label = Utils.getClosest(originalOptions.input1, ".cb-input");
          if (isSliderDisabled()) {
            originalOptions.input1.setAttribute("disabled", "true");
            label && Utils.addClass(label, "cb-disabled");
          }
        }
      } else {
        if (originalOptions.input2) {
          const inputID2 =
            Utils.attr(originalOptions.input2, "id") || Utils.uniqueID(5, "apricot_");
          originalOptions.input2.setAttribute("id", inputID2);
          Utils.attr(handle, "aria-controls", inputID2);
          Utils.attr(originalOptions.input2, "aria-controls", `${elemId}_handle2`);

          const label = Utils.getClosest(originalOptions.input2, ".cb-input");
          if (isSliderDisabled()) {
            originalOptions.input2.setAttribute("disabled", "true");
            label && Utils.addClass(label, "cb-disabled");
          }
        }
      }
      
      if (originalOptions.ariaControls) {
        const ariaControls = asArray(originalOptions.ariaControls);
        let controlValue = Utils.attr(handle, "aria-controls")
          ? ` ${Utils.attr(handle, "aria-controls")}`
          : "";

        if (handleNumber === 0) {
          ariaControls[0] &&
            Utils.attr(handle, "aria-controls", `${ariaControls[0]}${controlValue}`);
        } else {
          ariaControls[1] &&
            Utils.attr(handle, "aria-controls", `${ariaControls[1]}${controlValue}`);
        }
      }

      return origin;
    };

    // Insert nodes for connect elements
    const addConnect = (base, add) => {
      if (!add) {
        return false;
      }

      return addNodeTo(base, "cb-slider-connect");
    };

    // Add handles to the slider base.
    const addConnects = (connectOptions, base) => {
      const connectBase = addNodeTo(base, "cb-slider-connects");

      scope_Handles = [];
      scope_Connects = [];

      scope_Connects.push(addConnect(connectBase, connectOptions[0]));

      // [::::O====O====O====]
      // connectOptions = [0, 1, 1, 1]

      for (let i = 0; i < options.handles; i++) {
        // Keep a list of all added handles.
        scope_Handles.push(addOrigin(base, i));
        scope_HandleNumbers[i] = i;
        scope_Connects.push(addConnect(connectBase, connectOptions[i + 1]));
      }
    };

    const addTooltip = (handle, handleNumber) => {
      if (!options.tooltips || !options.tooltips[handleNumber]) {
        return false;
      }
      const tip = addNodeTo(handle.firstChild, "cb-slider-tooltip");
      Utils.attr(tip, "aria-hidden", "true");

      return tip;
    };

    const isSliderDisabled = () => {
      return scope_Target.hasAttribute("disabled") || scope_Target.hasAttribute("aria-disabled");
    };

    // Disable the slider dragging if any handle is disabled
    const isHandleDisabled = (handleNumber) => {
      const handle = scope_Handles[handleNumber].children[0];
       if (handle) {
        return handle.hasAttribute("disabled") || handle.hasAttribute("aria-disabled");
       }  else {
         return false;
       }
    };

    const removeTooltips = () => {
      if (scope_Tooltips) {
        removeEvent("apricot_update" + INTERNAL_EVENT_NS.tooltips);
        scope_Tooltips.forEach((tooltip) => {
          if (tooltip) {
            Utils.remove(tooltip);
          }
        });
        scope_Tooltips = null;
      }
    };

    // The tooltips option is a shorthand for using the 'update' event.
    const tooltips = () => {
      removeTooltips();

      // Tooltips are added with options.tooltips in original order.
      scope_Tooltips = scope_Handles.map(addTooltip);

      bindEvent(
        "apricot_update" + INTERNAL_EVENT_NS.tooltips,
        (values, handleNumber, unencoded) => {
          if (!scope_Tooltips || !options.tooltips) {
            return;
          }

          if (scope_Tooltips[handleNumber] === false) {
            return;
          }

          let formattedValue = values[handleNumber];

          if (options.tooltips[handleNumber] !== true && options.tooltips[handleNumber].to) {
            formattedValue = options.tooltips[handleNumber].to(unencoded[handleNumber]);
          }

          scope_Tooltips[handleNumber].innerHTML = formattedValue;
        }
      );
    };

    const aria = () => {
      removeEvent("apricot_update" + INTERNAL_EVENT_NS.aria);
      bindEvent(
        "apricot_update" + INTERNAL_EVENT_NS.aria,
        (values, handleNumber, unencoded, tap, positions) => {
          // Update Aria Values for all handles, as a change in one changes min and max values for the next.
          scope_HandleNumbers.forEach((index) => {
            const handle = scope_Handles[index];

            let min = checkHandlePosition(scope_Locations, index, 0, true, true, true);
            let max = checkHandlePosition(scope_Locations, index, 100, true, true, true);
            let now = positions[index];

            // Formatted value for display
            const text = String(options.ariaFormat.to(unencoded[index]));

            // Map to slider range values
            min = scope_Spectrum.fromStepping(min).toFixed(1);
            max = scope_Spectrum.fromStepping(max).toFixed(1);
            now = scope_Spectrum.fromStepping(now);

            handle.children[0].setAttribute("aria-valuemin", min);
            handle.children[0].setAttribute("aria-valuemax", max);
            handle.children[0].setAttribute("aria-valuenow", now.toFixed(1));
            handle.children[0].setAttribute("aria-valuetext", text);

            // adjust input values
            const input = document.querySelector(
              `#${handle.children[0].getAttribute("aria-controls")}`
            );
            if (input) {
              input.setAttribute("min", min);
              input.setAttribute("max", max);

              // TBd: what format
              if (typeof options.format.input === "function") {
                input.value = options.format.input(now);
              } else {
                input.value = options.format.to(now);
              }
            }
          });
        }
      );
    };

    const getGroup = (pips) => {
      // Use the range.
      if (pips.mode === "range" || pips.mode === "steps") {
        return scope_Spectrum.xVal;
      }

      if (pips.mode === "count") {
        if (pips.values < 2) {
          throw new Error("Slider: 'values' (>= 2) required for mode 'count'.");
        }

        // Divide 0 - 100 in 'count' parts.
        let interval = pips.values - 1;
        const spread = 100 / interval;

        const values = [];

        // List these parts and have them handled as 'positions'.
        while (interval--) {
          values[interval] = interval * spread;
        }

        values.push(100);

        return mapToRange(values, pips.stepped);
      }

      if (pips.mode === "positions") {
        // Map all percentages to on-range values.
        return mapToRange(pips.values, pips.stepped);
      }

      if (pips.mode === "values") {
        // If the value must be stepped, it needs to be converted to a percentage first.
        if (pips.stepped) {
          return pips.values.map((value) => {
            // Convert to percentage, apply step, return to value.
            return scope_Spectrum.fromStepping(
              scope_Spectrum.getStep(scope_Spectrum.toStepping(value))
            );
          });
        }

        // Otherwise, we can simply use the values.
        return pips.values;
      }

      return []; // pips.mode = never
    };

    const mapToRange = (values, stepped = undefined) => {
      return values.map((value) => {
        return scope_Spectrum.fromStepping(stepped ? scope_Spectrum.getStep(value) : value);
      });
    };

    const generateSpread = (pips) => {
      const safeIncrement = (value, increment) => {
        // Avoid floating point variance by dropping the smallest decimal places.
        return Number((value + increment).toFixed(7));
      };

      let group = getGroup(pips);
      const indexes = {};
      const firstInRange = scope_Spectrum.xVal[0];
      const lastInRange = scope_Spectrum.xVal[scope_Spectrum.xVal.length - 1];
      let ignoreFirst = false;
      let ignoreLast = false;
      let prevPct = 0;

      // Create a copy of the group, sort it and filter away all duplicates.
      group = unique(
        group.slice().sort((a, b) => {
          return a - b;
        })
      );

      // Make sure the range starts with the first element.
      if (group[0] !== firstInRange) {
        group.unshift(firstInRange);
        ignoreFirst = true;
      }

      // Likewise for the last one.
      if (group[group.length - 1] !== lastInRange) {
        group.push(lastInRange);
        ignoreLast = true;
      }

      group.forEach((current, index) => {
        // Get the current step and the lower + upper positions.
        let step;
        let i;
        let q;
        const low = current;
        let high = group[index + 1];
        let newPct;
        let pctDifference;
        let pctPos;
        let type;
        let steps;
        let realSteps;
        let stepSize;
        const isSteps = pips.mode === "steps";

        // When using 'steps' mode, use the provided steps.
        // Otherwise, we'll step on to the next subrange.
        if (isSteps) {
          step = scope_Spectrum.xNumSteps[index];
        }

        // Default to a 'full' step.
        if (!step) {
          step = high - low;
        }

        // If high is undefined we are at the last subrange.
        if (high === undefined) {
          high = low;
        }

        // Make sure step isn't 0, which would cause an infinite loop
        step = Math.max(step, 0.0000001);

        // Find all steps in the subrange.
        for (i = low; i <= high; i = safeIncrement(i, step)) {
          // Get the percentage value for the current step,
          // calculate the size for the subrange.
          newPct = scope_Spectrum.toStepping(i);
          pctDifference = newPct - prevPct;

          steps = pctDifference / (pips.density || 1);
          realSteps = Math.round(steps);

          // This ratio represents the amount of percentage-space a point indicates.
          // For a density 1 the points/percentage = 1. For density 2, that percentage needs to be re-divided.
          // Round the percentage offset to an even number, then divide by two
          // to spread the offset on both sides of the range.
          stepSize = pctDifference / realSteps;

          // Divide all points evenly, adding the correct number to this subrange.
          // Run up to <= so that 100% gets a point, event if ignoreLast is set.
          for (q = 1; q <= realSteps; q += 1) {
            // The ratio between the rounded value and the actual size might be ~1% off.
            // Correct the percentage offset by the number of points
            // per subrange. density = 1 will result in 100 points on the
            // full range, 2 for 50, 4 for 25, etc.
            pctPos = prevPct + q * stepSize;
            indexes[pctPos.toFixed(5)] = [scope_Spectrum.fromStepping(pctPos), 0];
          }

          // Determine the point type.
          type = group.indexOf(i) > -1 ? 1 : isSteps && 0;

          // Enforce the 'ignoreFirst' option by overwriting the type for 0.
          if (!index && ignoreFirst && i !== high) {
            type = 0;
          }

          if (!(i === high && ignoreLast)) {
            // Mark the 'type' of this point. 0 = plain, 1 = real value, 2 = step value.
            indexes[newPct.toFixed(5)] = [i, type];
          }

          // Update the percentage count.
          prevPct = newPct;
        }
      });

      return indexes;
    };

    const addMarking = (spread, filterFunc = undefined, formatter) => {
      const element = scope_Document.createElement("div");

      const valueSizeClasses = ["", "cb-slider-value-large"];
      const markerSizeClasses = ["", "cb-slider-marker-large"];

      Utils.addClass(element, "cb-slider-pips");
      Utils.attr(element, "aria-hidden", "true");

      const getClasses = (type, source) => {
        const a = source === "cb-slider-value";
        const sizeClasses = a ? valueSizeClasses : markerSizeClasses;

        return source + " " + sizeClasses[type];
      };

      const addSpread = (offset, value, type) => {
        type = filterFunc ? filterFunc(value, type) : type;

        // Add a marker for every point
        let node = addNodeTo(element, false);
        node.className = getClasses(type, "cb-slider-marker");
        node.style[options.style] = offset + "%";

        // Values are only appended for points marked '1' or '2'.
        if (type > 0) {
          node = addNodeTo(element, false);
          node.className = getClasses(type, "cb-slider-value");
          node.setAttribute("data-value", String(value));
          node.style[options.style] = offset + "%";
          node.innerHTML = String(formatter.to(value));
        }
      };

      // Append all points.
      Object.keys(spread).forEach((offset) => {
        addSpread(offset, spread[offset][0], spread[offset][1]);
      });

      return element;
    };

    const removePips = () => {
      if (scope_Pips) {
        Utils.remove(scope_Pips);
        scope_Pips = null;
      }
    };

    const pips = (pips) => {
      removePips();

      const spread = generateSpread(pips);
      const filter = pips.filter;
      const format = pips.format || {
        to: (value) => {
          return String(Math.round(value));
        },
      };

      scope_Pips = scope_Target.appendChild(addMarking(spread, filter, format));

      return scope_Pips;
    };

    // Shorthand for base dimensions.
    const baseSize = () => {
      const rect = scope_Base.getBoundingClientRect();

      return rect.width || scope_Base["offsetWidth"];
    };

    // Handler for attaching events trough a proxy.
    const attachEvent = (events, element, callback, data) => {
      // This function can be used to 'filter' events to the slider.
      // element is a node, not a nodeList

      const method = (event) => {
        const e = fixEvent(event, data.pageOffset, data.target || element);

        // fixEvent returns false if this event has a different target
        // when handling (multi-) touch events;
        if (!e) {
          return false;
        }

        // doNotReject is passed by all end events to make sure released touches
        // are not rejected, leaving the slider "stuck" to the cursor;
        if (isSliderDisabled() && !data.doNotReject) {
          return false;
        }

        // Stop if an active 'tap' transition is taking place.
        if (Utils.hasClass(scope_Target, "cb-slider-state-tap") && !data.doNotReject) {
          return false;
        }

        // Ignore right or middle clicks on start
        if (events === actions.start && e.buttons !== undefined && e.buttons > 1) {
          return false;
        }

        if (!supportsPassive) {
          e.preventDefault();
        }

        // e.calcPoint = e.points[options.ort];
        e.calcPoint = e.points[0];

        // Call the event handler with the event [ and additional data ].
        callback(e, data);

        return;
      };

      const methods = [];

      // Bind a closure on the target for every event type.
      events.split(" ").forEach((eventName) => {
        element.addEventListener(eventName, method, supportsPassive ? { passive: true } : false);
        methods.push([eventName, method]);
      });

      return methods;
    };

    // Provide a clean event with standardized offset values.
    const fixEvent = (e, pageOffset = undefined, eventTarget) => {
      // Filter the event to register the type, which can be
      // touch, mouse or pointer. Offset changes need to be
      // made on an event specific basis.
      const touch = e.type.indexOf("touch") === 0;
      const mouse = e.type.indexOf("mouse") === 0;
      let pointer = e.type.indexOf("pointer") === 0;

      let x = 0;
      let y = 0;

      // IE10 implemented pointer events with a prefix;
      if (e.type.indexOf("MSPointer") === 0) {
        pointer = true;
      }

      // Erroneous events seem to be passed in occasionally on iOS/iPadOS after user finishes interacting with
      // the slider. They appear to be of type MouseEvent, yet they don't have usual properties set. Ignore
      // events that have no touches or buttons associated with them.
      if (e.type === "mousedown" && !e.buttons && !e.touches) {
        return false;
      }

      // The only thing one handle should be concerned about is the touches that originated on top of it.
      if (touch) {
        // Returns true if a touch originated on the target.
        const isTouchOnTarget = (checkTouch) => {
          const target = checkTouch.target;

          return (
            target === eventTarget ||
            eventTarget.contains(target) ||
            (e.composed && e.composedPath().shift() === eventTarget)
          );
        };

        // In the case of touchstart events, we need to make sure there is still no more than one
        // touch on the target so we look amongst all touches.
        if (e.type === "touchstart") {
          const targetTouches = Array.prototype.filter.call(e.touches, isTouchOnTarget);

          // Do not support more than one touch per handle.
          if (targetTouches.length > 1) {
            return false;
          }

          x = targetTouches[0].pageX;
          y = targetTouches[0].pageY;
        } else {
          // In the other cases, find on changedTouches is enough.
          const targetTouch = Array.prototype.find.call(e.changedTouches, isTouchOnTarget);

          // Cancel if the target touch has not moved.
          if (!targetTouch) {
            return false;
          }

          x = targetTouch.pageX;
          y = targetTouch.pageY;
        }
      }

      pageOffset = pageOffset || getPageOffset(scope_Document);

      if (mouse || pointer) {
        x = e.clientX + pageOffset.x;
        y = e.clientY + pageOffset.y;
      }

      e.pageOffset = pageOffset;
      e.points = [x, y];
      e.cursor = mouse || pointer;

      return e;
    };

    // Translate a coordinate in the document to a percentage on the slider
    const calcPointToPercentage = (calcPoint) => {
      // const location = calcPoint - offset(scope_Base, options.ort);
      const location = calcPoint - offset(scope_Base, 0);
      let proposal = (location * 100) / baseSize();

      // Clamp proposal between 0% and 100%
      // Out-of-bound coordinates may occur when .noUi-base pseudo-elements
      // are used (e.g. contained handles feature)
      proposal = limit(proposal);

      return options.dir ? 100 - proposal : proposal;
    };

    // Find handle closest to a certain percentage on the slider
    const getClosestHandle = (clickedPosition) => {
      let smallestDifference = 100;
      let handleNumber = false;

      scope_Handles.forEach((handle, index) => {
        // Disabled handles are ignored
        if (isHandleDisabled(index)) {
          return;
        }

        const handlePosition = scope_Locations[index];
        const differenceWithThisHandle = Math.abs(handlePosition - clickedPosition);

        // Initial state
        const clickAtEdge = differenceWithThisHandle === 100 && smallestDifference === 100;

        // Difference with this handle is smaller than the previously checked handle
        const isCloser = differenceWithThisHandle < smallestDifference;
        const isCloserAfter =
          differenceWithThisHandle <= smallestDifference && clickedPosition > handlePosition;

        if (isCloser || isCloserAfter || clickAtEdge) {
          handleNumber = index;
          smallestDifference = differenceWithThisHandle;
        }
      });

      return handleNumber;
    };

    // Fire 'end' when a mouse or pen leaves the document.
    const documentLeave = (event, data) => {
      if (
        event.type === "mouseout" &&
        event.target.nodeName === "HTML" &&
        event.relatedTarget === null
      ) {
        eventEnd(event, data);
      }
    };

    // Handle movement on document for handle and range drag.
    const eventMove = (event, data) => {
      // Check value of .buttons in 'start' to work around a bug in IE10 mobile (data.buttonsProperty).
      // https://connect.microsoft.com/IE/feedback/details/927005/mobile-ie10-windows-phone-buttons-property-of-pointermove-event-always-zero
      // IE9 has .buttons and .which zero on mousemove.
      // Firefox breaks the spec MDN defines.
      if (
        navigator.appVersion.indexOf("MSIE 9") === -1 &&
        event.buttons === 0 &&
        data.buttonsProperty !== 0
      ) {
        return eventEnd(event, data);
      }

      // Check if we are moving up or down
      const movement = (options.dir ? -1 : 1) * (event.calcPoint - data.startCalcPoint);

      // Convert the movement into a percentage of the slider width/height
      const proposal = (movement * 100) / data.baseSize;

      moveHandles(movement > 0, proposal, data.locations, data.handleNumbers, data.connect);
    };

    // Unbind move events on document, call callbacks.
    const eventEnd = (event, data) => {
      // The handle is no longer active, so remove the class.
      if (data.handle) {
        Utils.removeClass(data.handle, "cb-slider-active");
        scope_ActiveHandlesCount -= 1;
      }

      // Unbind the move and end events, which are added on 'start'.
      data.listeners.forEach((c) => {
        scope_DocumentElement.removeEventListener(c[0], c[1]);
      });

      if (scope_ActiveHandlesCount === 0) {
        setZindex();

        // Remove cursor styles and text-selection events bound to the body.
        if (event.cursor) {
          scope_Body.style.cursor = "";
          scope_Body.removeEventListener("selectstart", preventDefault);
        }
      }

      data.handleNumbers.forEach((handleNumber) => {
        fireEvent("apricot_change", handleNumber);
        fireEvent("apricot_set", handleNumber);
        fireEvent("apricot_end", handleNumber);
      });
    };

    // Bind move events on document.
    const eventStart = (event, data) => {
      // Ignore event if any handle is disabled
      if (data.handleNumbers.some(isHandleDisabled)) {
        return;
      }

      let handle;

      if (data.handleNumbers.length === 1) {
        const handleOrigin = scope_Handles[data.handleNumbers[0]];

        handle = handleOrigin.children[0];
        scope_ActiveHandlesCount += 1;

        // Mark the handle as 'active' so it can be styled.
        Utils.addClass(handle, "cb-slider-active");
      }

      // A drag should never propagate up to the 'tap' event.
      event.stopPropagation();

      // Record the event listeners.
      const listeners = [];

      // Attach the move and end events.
      const moveEvent = attachEvent(actions.move, scope_DocumentElement, eventMove, {
        // The event target has changed so we need to propagate the original one so that we keep
        // relying on it to extract target touches.
        target: event.target,
        handle: handle,
        connect: data.connect,
        listeners: listeners,
        startCalcPoint: event.calcPoint,
        baseSize: baseSize(),
        pageOffset: event.pageOffset,
        handleNumbers: data.handleNumbers,
        buttonsProperty: event.buttons,
        locations: scope_Locations.slice(),
      });

      const endEvent = attachEvent(actions.end, scope_DocumentElement, eventEnd, {
        target: event.target,
        handle: handle,
        listeners: listeners,
        doNotReject: true,
        handleNumbers: data.handleNumbers,
      });

      const outEvent = attachEvent("mouseout", scope_DocumentElement, documentLeave, {
        target: event.target,
        handle: handle,
        listeners: listeners,
        doNotReject: true,
        handleNumbers: data.handleNumbers,
      });

      // We want to make sure we pushed the listeners in the listener list rather than creating
      // a new one as it has already been passed to the event handlers.
      listeners.push.apply(listeners, moveEvent.concat(endEvent, outEvent));

      // Text selection isn't an issue on touch devices,
      // so adding cursor styles can be skipped.
      if (event.cursor) {
        // Prevent the 'I' cursor and extend the range-drag cursor.
        scope_Body.style.cursor = getComputedStyle(event.target).cursor;

        // Prevent text selection when dragging the handles.
        // In Slider <= 9.2.0, this was handled by calling preventDefault on mouse/touch start/move,
        // which is scroll blocking. The selectstart event is supported by FireFox starting from version 52,
        // meaning the only holdout is iOS Safari. This doesn't matter: text selection isn't triggered there.
        // The 'cursor' flag is false.
        // See: http://caniuse.com/#search=selectstart
        scope_Body.addEventListener("selectstart", preventDefault, false);
      }

      data.handleNumbers.forEach((handleNumber) => {
        fireEvent("apricot_start", handleNumber);
      });
    };

    // Move closest handle to tapped location.
    const eventTap = (event) => {
      // The tap event shouldn't propagate up
      event.stopPropagation();

      const proposal = calcPointToPercentage(event.calcPoint);
      const handleNumber = getClosestHandle(proposal);

      // Tackle the case that all handles are 'disabled'.
      if (handleNumber === false) {
        return;
      }

      // Flag the slider as it is now in a transitional state.
      // Transition takes a configurable amount of ms (default 300). Re-enable the slider after that.
      if (!options.events.snap) {
        addClassFor(scope_Target, "cb-slider-tap", 300);
      }

      setHandle(handleNumber, proposal, true, true);

      setZindex();

      fireEvent("apricot_slide", handleNumber, true);
      fireEvent("apricot_update", handleNumber, true);

      if (!options.events.snap) {
        fireEvent("apricot_change", handleNumber, true);
        fireEvent("apricot_set", handleNumber, true);
      } else {
        eventStart(event, { handleNumbers: [handleNumber] });
      }
    };

    // Handles keydown on focused handles
    // Don't move the document when pressing arrow keys on focused handles
    const eventKeydown = (event, handleNumber) => {
      if (isSliderDisabled() || isHandleDisabled(handleNumber)) {
        return false;
      }

      // MAS: check
      const horizontalKeys = ["Left", "Right"];
      const verticalKeys = ["Down", "Up"];
      const largeStepKeys = ["PageDown", "PageUp"];
      const edgeKeys = ["Home", "End"];

      const key = event.key.replace("Arrow", "");

      const isLargeDown = key === largeStepKeys[0];
      const isLargeUp = key === largeStepKeys[1];
      const isDown = key === verticalKeys[0] || key === horizontalKeys[0] || isLargeDown;
      const isUp = key === verticalKeys[1] || key === horizontalKeys[1] || isLargeUp;
      const isMin = key === edgeKeys[0];
      const isMax = key === edgeKeys[1];

      if (!isDown && !isUp && !isMin && !isMax) {
        return true;
      }

      event.preventDefault();

      let to;

      if (isUp || isDown) {
        const direction = isDown ? 0 : 1;
        const steps = getNextStepsForHandle(handleNumber);
        let step = steps[direction];

        // At the edge of a slider, do nothing
        if (step === null) {
          return false;
        }

        // Mas: keyboardPageMultiplier
        if (isLargeUp || isLargeDown) {
          step *= options.keyboardPageMultiplier;
        } else {
          step *= options.keyboardDefaultStep;
        }

        // Step over zero-length ranges
        step = Math.max(step, 0.0000001);

        // Decrement for down steps
        step = (isDown ? -1 : 1) * step;

        to = scope_Values[handleNumber] + step;
      } else if (isMax) {
        // End key
        to = options.spectrum.xVal[options.spectrum.xVal.length - 1];
      } else {
        // Home key
        to = options.spectrum.xVal[0];
      }

      setHandle(handleNumber, scope_Spectrum.toStepping(to), true, true);

      fireEvent("apricot_slide", handleNumber);
      fireEvent("apricot_update", handleNumber);
      fireEvent("apricot_change", handleNumber);
      fireEvent("apricot_set", handleNumber);

      return false;
    };

    // Attach events to several slider parts.
    const bindSliderEvents = (behaviour) => {
      // Attach the standard drag event to the handles.
      scope_Handles.forEach((handle, index) => {
        // These events are only bound to the visual handle
        // element, not the 'real' origin element.
        attachEvent(actions.start, handle.children[0], eventStart, {
          handleNumbers: [index],
        });
      });

      // Attach the tap event to the slider base.
      if (behaviour.tap) {
        attachEvent(actions.start, scope_Base, eventTap, {});
      }
    };

    // Attach events to several slider parts.
    const bindInputEvents = () => {
      if (originalOptions.input1) {
        originalOptions.input1.addEventListener("change", function () {
          valueSet([this.value, null]);
        });
      }

      if (originalOptions.input2) {
        originalOptions.input2.addEventListener("change", function () {
          valueSet([null, this.value]);
        });
      }
    };

    // Attach an event to this slider, possibly including a namespace
    const bindEvent = (namespacedEvent, callback) => {
      scope_Events[namespacedEvent] = scope_Events[namespacedEvent] || [];
      scope_Events[namespacedEvent].push(callback);

      // If the event bound is 'update,' fire it immediately for all handles.
      if (namespacedEvent.split(".")[0] === "apricot_update") {
        scope_Handles.forEach((a, index) => {
          fireEvent("apricot_update", index);
        });
      }
    };

    const isInternalNamespace = (namespace) => {
      return namespace === INTERNAL_EVENT_NS.aria || namespace === INTERNAL_EVENT_NS.tooltips;
    };

    // Undo attachment of event
    const removeEvent = (namespacedEvent) => {
      const event = namespacedEvent && namespacedEvent.split(".")[0];
      const namespace = event ? namespacedEvent.substring(event.length) : namespacedEvent;

      Object.keys(scope_Events).forEach((bind) => {
        const tEvent = bind.split(".")[0];
        const tNamespace = bind.substring(tEvent.length);
        if ((!event || event === tEvent) && (!namespace || namespace === tNamespace)) {
          // only delete protected internal event if intentional
          if (!isInternalNamespace(tNamespace) || namespace === tNamespace) {
            delete scope_Events[bind];
          }
        }
      });
    };

    // External event handling
    const fireEvent = (eventName, handleNumber, tap = undefined) => {
      Object.keys(scope_Events).forEach((targetEvent) => {
        const eventType = targetEvent.split(".")[0];

        if (eventName === eventType) {
          scope_Events[targetEvent].forEach((callback) => {
            callback.call(
              // Use the slider public API as the scope ('this')
              scope_Self,
              // Return values as array, so arg_1[arg_2] is always valid.
              scope_Values.map(options.format.to),
              // Handle index, 0 or 1
              handleNumber,
              // Un-formatted slider values
              scope_Values.slice(),
              // Event is fired by tap, true or false
              tap || false,
              // Left offset of the handle, in relation to the slider
              scope_Locations.slice(),
              // add the slider public API to an accessible parameter when this is unavailable
              scope_Self
            );
          });
        }
      });
    };

    // Split out the handle positioning logic so the Move event can use it, too
    const checkHandlePosition = (
      reference,
      handleNumber,
      to,
      lookBackward,
      lookForward,
      getValue
    ) => {
      let distance;

      // For sliders with multiple handles, limit movement to the other handle.
      // Apply the margin option by adding it to the handle positions.
      // if (scope_Handles.length > 1 && !options.events.unconstrained) {
      if (scope_Handles.length > 1) {
        if (lookBackward && handleNumber > 0) {
          distance = scope_Spectrum.getAbsoluteDistance(
            reference[handleNumber - 1],
            options.margin,
            false
          );
          to = Math.max(to, distance);
        }

        if (lookForward && handleNumber < scope_Handles.length - 1) {
          distance = scope_Spectrum.getAbsoluteDistance(
            reference[handleNumber + 1],
            options.margin,
            true
          );
          to = Math.min(to, distance);
        }
      }

      // The limit option has the opposite effect, limiting handles to a
      // maximum distance from another. Limit must be > 0, as otherwise
      // handles would be unmovable.
      if (scope_Handles.length > 1 && options.limit) {
        if (lookBackward && handleNumber > 0) {
          distance = scope_Spectrum.getAbsoluteDistance(
            reference[handleNumber - 1],
            options.limit,
            false
          );
          to = Math.min(to, distance);
        }

        if (lookForward && handleNumber < scope_Handles.length - 1) {
          distance = scope_Spectrum.getAbsoluteDistance(
            reference[handleNumber + 1],
            options.limit,
            true
          );
          to = Math.max(to, distance);
        }
      }

      // The padding option keeps the handles a certain distance from the
      // edges of the slider. Padding must be > 0.
      if (options.padding) {
        if (handleNumber === 0) {
          distance = scope_Spectrum.getAbsoluteDistance(0, options.padding[0], false);
          to = Math.max(to, distance);
        }

        if (handleNumber === scope_Handles.length - 1) {
          distance = scope_Spectrum.getAbsoluteDistance(100, options.padding[1], true);
          to = Math.min(to, distance);
        }
      }

      to = scope_Spectrum.getStep(to);

      // Limit percentage to the 0 - 100 range
      to = limit(to);

      // Return false if handle can't move
      if (to === reference[handleNumber] && !getValue) {
        return false;
      }

      return to;
    };

    // Uses slider orientation to create CSS rules. a = base value;
    const inRuleOrder = (v, a) => {
      return v + ", " + a;
    };

    // Moves handle(s) by a percentage
    // (bool, % to move, [% where handle started, ...], [index in scope_Handles, ...])
    const moveHandles = (upward, proposal, locations, handleNumbers, connect) => {
      const proposals = locations.slice();

      let b = [!upward, upward];
      let f = [upward, !upward];

      // Copy handleNumbers so we don't change the dataset
      handleNumbers = handleNumbers.slice();

      // Check to see which handle is 'leading'.
      // If that one can't move the second can't either.
      if (upward) {
        handleNumbers.reverse();
      }

      // Step 1: get the maximum percentage that any of the handles can move
      if (handleNumbers.length > 1) {
        handleNumbers.forEach((handleNumber, o) => {
          const to = checkHandlePosition(
            proposals,
            handleNumber,
            proposals[handleNumber] + proposal,
            b[o],
            f[o],
            false
          );

          // Stop if one of the handles can't move.
          if (to === false) {
            proposal = 0;
          } else {
            proposal = to - proposals[handleNumber];
            proposals[handleNumber] = to;
          }
        });
      }

      // If using one handle, check backward AND forward
      else {
        b = f = [true];
      }

      let state = false;

      // Step 2: Try to set the handles with the found percentage
      handleNumbers.forEach((handleNumber, o) => {
        state = setHandle(handleNumber, locations[handleNumber] + proposal, b[o], f[o]) || state;
      });

      // Step 3: If a handle moved, fire events
      if (state) {
        handleNumbers.forEach((handleNumber) => {
          fireEvent("apricot_update", handleNumber);
          fireEvent("apricot_slide", handleNumber);
        });
      }
    };

    // Takes a base value and an offset. This offset is used for the connect bar size.
    // In the initial design for this feature, the origin element was 1% wide.
    // Unfortunately, a rounding bug in Chrome makes it impossible to implement this feature
    const transformDirection = (a, b) => {
      return options.dir ? 100 - a - b : a;
    };

    // Updates scope_Locations and scope_Values, updates visual state
    const updateHandlePosition = (handleNumber, to) => {
      // Update locations.
      scope_Locations[handleNumber] = to;

      // Convert the value to the slider stepping/range.
      scope_Values[handleNumber] = scope_Spectrum.fromStepping(to);

      const translation = transformDirection(to, 0) - scope_DirOffset;
      const translateRule = "translate(" + inRuleOrder(translation + "%", "0") + ")";

      scope_Handles[handleNumber].style[options.transformRule] = translateRule;

      updateConnect(handleNumber);
      updateConnect(handleNumber + 1);
    };

    // Handles before the slider middle are stacked later = higher,
    // Handles after the middle later is lower
    // [[7] [8] .......... | .......... [5] [4]
    const setZindex = () => {
      scope_HandleNumbers.forEach((handleNumber) => {
        const dir = scope_Locations[handleNumber] > 50 ? -1 : 1;
        const zIndex = 3 + (scope_Handles.length + dir * handleNumber);
        scope_Handles[handleNumber].style.zIndex = String(zIndex);
      });
    };

    // Test suggested values and apply margin, step.
    // if exactInput is true, don't run checkHandlePosition, then the handle can be placed in between steps
    const setHandle = (handleNumber, to, lookBackward, lookForward, exactInput = null) => {
      if (!exactInput) {
        to = checkHandlePosition(
          scope_Locations,
          handleNumber,
          to,
          lookBackward,
          lookForward,
          false
        );
      }

      if (to === false) {
        return false;
      }

      updateHandlePosition(handleNumber, to);

      return true;
    };

    // Updates style attribute for connect nodes
    const updateConnect = (index) => {
      // Skip connects set to false
      if (!scope_Connects[index]) {
        return;
      }

      let l = 0;
      let h = 100;

      if (index !== 0) {
        l = scope_Locations[index - 1];
      }

      if (index !== scope_Connects.length - 1) {
        h = scope_Locations[index];
      }

      // We use two rules:
      // 'translate' to change the left/top offset;
      // 'scale' to change the width of the element;
      // As the element has a width of 100%, a translation of 100% is equal to 100% of the parent (.noUi-base)
      const connectWidth = h - l;
      const translateRule =
        "translate(" + inRuleOrder(transformDirection(l, connectWidth) + "%", "0") + ")";
      const scaleRule = "scale(" + inRuleOrder(connectWidth / 100, "1") + ")";

      scope_Connects[index].style[options.transformRule] = translateRule + " " + scaleRule;
    };

    // Parses value passed to .set method. Returns current value if not parse-able.
    const resolveToValue = (to, handleNumber) => {
      // Setting with null indicates an 'ignore'.
      // Inputting 'false' is invalid.
      if (to === null || to === false || to === undefined) {
        return scope_Locations[handleNumber];
      }

      // If a formatted number was passed, attempt to decode it.
      if (typeof to === "number") {
        to = String(to);
      }

      to = options.format.from(to);

      if (to !== false) {
        to = scope_Spectrum.toStepping(to);
      }

      // If parsing the number failed, use the current value.
      if (to === false || isNaN(to)) {
        return scope_Locations[handleNumber];
      }

      return to;
    };

    const useAnimation = (options) => {
      return Utils.reduceMotionChanged() ? false : options.animation;
    };

    // Set the slider value.
    const valueSet = (input, fireSetEvent = undefined, exactInput = undefined) => {
      const values = asArray(input);
      const isInit = scope_Locations[0] === undefined;

      // Event fires by default
      fireSetEvent = fireSetEvent === undefined ? true : fireSetEvent;

      // Animation is optional.
      // Make sure the initial values were set before using animated placement.
      if (useAnimation(options) && !isInit) {
        addClassFor(scope_Target, "cb-slider-tap", 300);
      }

      // First pass, without lookAhead but with lookBackward. Values are set from left to right.
      scope_HandleNumbers.forEach((handleNumber) => {
        setHandle(
          handleNumber,
          resolveToValue(values[handleNumber], handleNumber),
          true,
          false,
          exactInput
        );
      });

      let i = scope_HandleNumbers.length === 1 ? 0 : 1;

      // Spread handles evenly across the slider if the range has no size (min=max)
      if (isInit && scope_Spectrum.hasNoSize()) {
        exactInput = true;

        scope_Locations[0] = 0;

        if (scope_HandleNumbers.length > 1) {
          const space = 100 / (scope_HandleNumbers.length - 1);

          scope_HandleNumbers.forEach((handleNumber) => {
            scope_Locations[handleNumber] = handleNumber * space;
          });
        }
      }

      // Secondary passes. Now that all base values are set, apply constraints.
      for (; i < scope_HandleNumbers.length; ++i) {
        scope_HandleNumbers.forEach((handleNumber) => {
          setHandle(handleNumber, scope_Locations[handleNumber], true, true, exactInput);
        });
      }

      setZindex();

      scope_HandleNumbers.forEach((handleNumber) => {
        fireEvent("apricot_update", handleNumber);

        // Fire the event only for handles that received a new value
        if (values[handleNumber] !== null && fireSetEvent) {
          fireEvent("apricot_set", handleNumber);
        }
      });
    };

    // Reset slider to initial values
    const valueReset = (fireSetEvent) => {
      valueSet(options.start, fireSetEvent);
    };

    const disableSlider = (disable) => {
      if(disable) {
        Utils.attr(scope_Target, "aria-disabled", "true")
      } else {
        Utils.removeAttr(scope_Target, "aria-disabled")
      }
      scope_Handles.forEach((handle, index) => {
        disableHandle(index, disable);
      });
    };

    const disableHandle = (handleNumber, disable) => {
      const handle = scope_Handles[handleNumber].children[0];
      if (!handle) return;

      if (disable) {
        Utils.attr(handle, "aria-disabled", "true");
        Utils.attr(handle, "tabindex", "-1");
      } else {
        Utils.removeAttr(handle, "aria-disabled");
        Utils.attr(handle, "tabindex", "0");
      }

      if (handleNumber === 0) {
        if (originalOptions.input1) {
          const label = Utils.getClosest(originalOptions.input1, ".cb-input");
          if (disable) {
            Utils.attr(originalOptions.input1, "disabled", "true");
            label && Utils.addClass(label, "cb-disabled");
          } else {
            Utils.removeAttr(originalOptions.input1, "disabled");
            label && Utils.removeClass(label, "cb-disabled");
          }
        }
      } else {
        if (originalOptions.input2) {
          const label = Utils.getClosest(originalOptions.input2, ".cb-input");
          if (disable) {
            Utils.attr(originalOptions.input2, "disabled", "true");
            label && Utils.addClass(label, "cb-disabled");
          } else {
            Utils.removeAttr(originalOptions.input2, "disabled");
            label && Utils.removeClass(label, "cb-disabled");
          }
        }
      }
    };

    // Set value for a single handle
    const valueSetHandle = (handleNumber, value, fireSetEvent, exactInput) => {
      // Ensure numeric input
      handleNumber = Number(handleNumber);

      if (!(handleNumber >= 0 && handleNumber < scope_HandleNumbers.length)) {
        throw new Error("Slider: invalid handle number, got: " + handleNumber);
      }

      // Look both backward and forward, since we don't want this handle to "push" other handles
      // The exactInput argument can be used to ignore slider stepping
      setHandle(handleNumber, resolveToValue(value, handleNumber), true, true, exactInput);

      fireEvent("apricot_update", handleNumber);

      if (fireSetEvent) {
        fireEvent("apricot_set", handleNumber);
      }
    };

    // Get the slider value.
    const valueGet = (unencoded = false) => {
      if (unencoded) {
        // return a copy of the raw values
        return scope_Values.length === 1 ? scope_Values[0] : scope_Values.slice(0);
      }
      const values = scope_Values.map(options.format.to);

      // If only one handle is used, return a single value.
      if (values.length === 1) {
        return values[0];
      }

      return values;
    };

    // Removes classes from the root and empties it.
    const destroy = () => {
      // remove protected internal listeners
      removeEvent(INTERNAL_EVENT_NS.aria);
      removeEvent(INTERNAL_EVENT_NS.tooltips);

      while (scope_Target.firstChild) {
        scope_Target.removeChild(scope_Target.firstChild);
      }

      delete scope_Target.slider;
    };

    const getNextStepsForHandle = (handleNumber) => {
      const location = scope_Locations[handleNumber];
      const nearbySteps = scope_Spectrum.getNearbySteps(location);
      const value = scope_Values[handleNumber];
      let increment = nearbySteps.thisStep.step;
      let decrement = null;

      // If snapped, directly use defined step value
      if (options.snap) {
        return [
          value - nearbySteps.stepBefore.startValue || null,
          nearbySteps.stepAfter.startValue - value || null,
        ];
      }

      // If the next value in this step moves into the next step,
      // the increment is the start of the next step - the current value
      if (increment !== false) {
        if (value + increment > nearbySteps.stepAfter.startValue) {
          increment = nearbySteps.stepAfter.startValue - value;
        }
      }

      // If the value is beyond the starting point
      if (value > nearbySteps.thisStep.startValue) {
        decrement = nearbySteps.thisStep.step;
      } else if (nearbySteps.stepBefore.step === false) {
        decrement = false;
      }

      // If a handle is at the start of a step, it always steps back into the previous step first
      else {
        decrement = value - nearbySteps.stepBefore.highestStep;
      }

      // Now, if at the slider edges, there is no in/decrement
      if (location === 100) {
        increment = null;
      } else if (location === 0) {
        decrement = null;
      }

      // the comparison for the decrement step can have some rounding issues.
      const stepDecimals = scope_Spectrum.countStepDecimals();

      // Round
      if (increment !== null && increment !== false) {
        increment = Number(increment.toFixed(stepDecimals));
      }

      if (decrement !== null && decrement !== false) {
        decrement = Number(decrement.toFixed(stepDecimals));
      }

      return [decrement, increment];
    };

    // Get the current step size for the slider.
    const getNextSteps = () => {
      return scope_HandleNumbers.map(getNextStepsForHandle);
    };

    // Updatable: margin, limit, padding, step, range, animate, snap
    const updateOptions = (optionsToUpdate, fireSetEvent) => {
      // Spectrum is created using the range, snap, direction and step options.
      // 'snap' and 'step' can be updated.
      // If 'snap' and 'step' are not passed, they should remain unchanged.
      const v = valueGet();

      const updateAble = [
        "margin",
        "limit",
        "padding",
        "range",
        "snap",
        "step",
        "format",
        "pips",
        "tooltips",
      ];

      // Only change options that we're actually passed to update.
      updateAble.forEach((name) => {
        // Check for undefined. null removes the value.
        if (optionsToUpdate[name] !== undefined) {
          originalOptions[name] = optionsToUpdate[name];
        }
      });

      const newOptions = testOptions(originalOptions);

      // Load new options into the slider state
      updateAble.forEach((name) => {
        if (optionsToUpdate[name] !== undefined) {
          options[name] = newOptions[name];
        }
      });

      scope_Spectrum = newOptions.spectrum;

      // Limit, margin and padding depend on the spectrum but are stored outside of it.
      options.margin = newOptions.margin;
      options.limit = newOptions.limit;
      options.padding = newOptions.padding;

      // Update pips, removes existing.
      if (options.pips) {
        pips(options.pips);
      } else {
        removePips();
      }

      // Update tooltips, removes existing.
      if (options.tooltips) {
        tooltips();
      } else {
        removeTooltips();
      }

      // Invalidate the current positioning so valueSet forces an update.
      scope_Locations = [];

      valueSet(isSet(optionsToUpdate.start) ? optionsToUpdate.start : v, fireSetEvent);
    };

    // Initialization steps
    const setupSlider = () => {
      // Create the base element, initialize HTML and set classes.
      scope_Base = addNodeTo(scope_Target, "cb-slider-base");
      addConnects(options.connect, scope_Base);

      // Attach user events.
      bindSliderEvents(options.events);

      // Attach Input events.
      options.handleInputChange && bindInputEvents();

      // Use the public value method to set the start values.
      valueSet(options.start);

      if (options.pips) {
        pips(options.pips);
      }

      if (options.tooltips) {
        // always show tooltip
        if (options.tooltips && options.tooltips[0].sticky) {
          Utils.addClass(scope_Base, "cb-slider-sticky-tooltip");
        }
        tooltips();
      }

      aria();
    };

    setupSlider();

    const scope_Self = {
      destroy: destroy,
      steps: getNextSteps,
      on: bindEvent,
      off: removeEvent,
      get: valueGet,
      set: valueSet,
      setHandle: valueSetHandle,
      reset: valueReset,
      disableSlider: disableSlider,
      disableHandle: disableHandle,
      options: originalOptions,
      updateOptions: updateOptions,
      target: scope_Target,
      removePips: removePips,
      removeTooltips: removeTooltips,
      getPositions: () => {
        return scope_Locations.slice();
      },
      getTooltips: () => {
        return scope_Tooltips;
      },
      getOrigins: () => {
        return scope_Handles;
      },
      pips: pips,
    };

    return scope_Self;
  };

  // Run the standard initializer
  const initialize = (originalOptions) => {
    if (!Utils.elemExists(originalOptions.elem)) {
      throw new Error("Slider: create requires a single element, got: " + target);
    }

    const target = originalOptions.elem;

    // Throw an error if the slider was already initialized.
    if (target.slider) {
      throw new Error("Slider: Slider was already initialized.");
    }
    const elemId = Utils.attr(target, "id") || Utils.uniqueID(5, "apricot_");
    target.setAttribute("id", elemId);

    // Test the options and create the slider environment;
    const options = testOptions(originalOptions);
    const api = scope(target, options, originalOptions);

    target.slider = api;

    return api;
  };

  return {
    init: initialize,
  };
})();

export default Slider;
