/* ========================================================================
 * Apricot's Local Navigation
 * ======================================================================== */

// SCSS
import "../scss/includes/apricot-base.scss";
import "../scss/includes/local-navigation.scss";

// javaScript
import Utils from "./CBUtils";
import CBDropdown from "./CBDropdown";

/**
 * Local Navigation
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Boolean} data.sticky
 * @param {Boolean} data.selected
 * @param {Boolean} data.dropdownCloseOnClick
 * @param {Boolean} data.mobileLayout
 * @param {Boolean} data.mobileEscClose
 * @param {String} data.mobileMainLabel
 * @param {String} data.mobileSubLabel
 * @param {Boolean} data.mobileInteractionClose
 * @param {Function} data.callBack
 * @param {Function} data.callBackMobile
 * @param {Function} data.callBackDesktop
 * @param {Function} data.callBackMore
 * @returns {{destroy: Function}}
 */

const LocalNavigation = (data = {}) => {
  const defaultData = {
    elem: null,
    sticky: true,
    selected: false,
    dropdownCloseOnClick: false,
    mobileLayout: true,
    mobileEscClose: true,
    mobileInteractionClose: false,
    mobileMainLabel: "mobile menu",
    mobileSubLabel: "menu items for",
    callBack: null,
    callBackMobile: null,
    callBackDesktop: null,
    callBackMore: null,
  };

  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  if (!Utils.elemExists(elem)) return null;

  let more = null;
  let moreDropdown = null;
  let moreMenu = null;
  let mWidth = 0;
  let sWidth = 0;

  let desktopNav = null;
  let navItems = null;

  let mobileTrigger = null;
  let mobileNav = null;
  let parentCount = 0;
  let panelCount = 0;

  let pluginStart = true;
  let scrollHappened = false;
  let offsetTop = 0;
  let resizeId = 0;
  let backIDs = [];
  let desktopAriaLabel = "";

  const init = () => {
    elem.localNav = "cb";

    desktopNav = elem.querySelector(".cb-desktop-navigation");

    let desktopNavContainer = desktopNav.querySelector(".cb-nav-container");
    if (Utils.elemExists(desktopNavContainer)) {
      if (desktopNavContainer.getAttribute("aria-label")) {
        desktopAriaLabel = desktopNavContainer.getAttribute("aria-label");
      }
    }

    mobileTrigger = elem.querySelector(".cb-mobile-trigger");
    mobileNav = elem.querySelector(".cb-mobile-navigation");

    navItems = elem.querySelector(".cb-nav-items");
    more = elem.querySelector(".cb-more");

    const promoCount = navItems.querySelectorAll(".cb-promotional").length;
    if (promoCount > 0) {
      const lastPromo = navItems.querySelectorAll(".cb-promotional")[promoCount - 1];
      Utils.addClass(lastPromo, "cb-last-of-type");
    }

    if (data.mobileLayout) {
      if (Utils.elemExists(mobileTrigger) && Utils.elemExists(mobileNav)) {
        // show mobile
        mobileTrigger.addEventListener("click", (e) => {
          e.preventDefault();

          openMobileNav();
        });
        if (data.mobileEscClose) {
          document.addEventListener("keydown", escClose);
        }

        Utils.breakpoints();
        // make sure the panels are closed when leaving xs
        document.addEventListener("apricot_breakpointChange", closePanelsOnResize);

        // get list and build
        listNavItems();
      }
    }

    setDesktopLinks();
    if (data.sticky) {
      window.addEventListener("scroll", scrollFunction);
    }

    if (Utils.elemExists(more)) {
      moreMenu = more.querySelector(".cb-dropdown-menu ul");
      getWidths();
      Utils.addClass(more, "cb-hidden");

      // make sure all nav items have an id
      navItems.querySelectorAll(".cb-nav-link").forEach((link) => {
        const id = Utils.attr(link, "id") ? Utils.attr(link, "id") : Utils.uniqueID(5, "apricot_");
        Utils.attr(link, "id", id);
      });

      trackMoreBehavior();
      window.addEventListener("resize", delayedTrackMoreBehavior);
    }

    // activate dropdown
    activateDropdown();
    // adjust dropdown behavior
    dropdownToggleEvent();

    pluginStart = false;
  };

  // start more adjustment after resize has stooped
  const delayedTrackMoreBehavior = () => {
    clearTimeout(resizeId);
    resizeId = setTimeout(trackMoreBehavior, 200);
  };

  const scrollFunction = () => {
    if (!scrollHappened) {
      offsetTop = Utils.offset(elem) ? Utils.offset(elem).top : 0;
      scrollHappened = true;
    }

    if (document.body.scrollTop > offsetTop || document.documentElement.scrollTop > offsetTop) {
      Utils.addClass(elem, "cb-sticky-local-navigation");
    } else {
      Utils.removeClass(elem, "cb-sticky-local-navigation");
    }
  };

  const activateDropdown = () => {
    document.querySelectorAll(".cb-dropdown").forEach((elem) => {
      const parent = Utils.parent(elem);
      if (Utils.hasClass(parent, "cb-more")) {
        activateMoreDropdown();
      } else {
        CBDropdown({
          elem: elem,
          closeOnClick: data.dropdownCloseOnClick
        });
      }
    });
  };

  const activateMoreDropdown = () => {
    if (moreDropdown) {
      moreDropdown.keyBoardReset();
    } else {
      moreDropdown = CBDropdown({
        elem: elem.querySelector(".cb-more .cb-dropdown"),
      });
    }
  };

  const dropdownToggleEvent = (e) => {
    elem.querySelectorAll(".cb-dropdown").forEach((dropdown) => {
      dropdown.addEventListener("apricot_dropdownKeyboardToggle", (e) => {
        if (e.data && e.data.tab && !e.data.shiftTab) {
          moveToNext(dropdown);
        }
      });
    });
  };

  const moveToNext = (acDropdown) => {
    const list = elem.querySelectorAll(".cb-menu-list-horizontal > ul > li");
    let items = [];
    let index = 0;

    Array.prototype.forEach.call(list, (node) => {
      if (!Utils.hasClass(node, "cb-hidden")) {
        const link = node.querySelectorAll(".cb-menu-link")[0]
          ? node.querySelectorAll(".cb-menu-link")[0]
          : node.querySelector(".cb-dropdown");
        if (Utils.elemExists(link)) {
          items.push(link);
        }
      }
    });

    Array.prototype.forEach.call(items, (item, i) => {
      if (acDropdown === item) {
        index = i;
      }
    });

    index += 1;

    if (index > items.length) {
      return false;
    } else {
      const newActive = items[index];

      setTimeout(() => {
        newActive.focus();
      }, 60);
    }
  };

  // A11Y
  const escClose = (e) => {
    // If toast esc is not in place
    const body = document.getElementsByTagName("body")[0];
    if (Utils.attr(body, "data-cb-esc")) return;

    if (e.keyCode === 27) closeMobileNav();
  };

  const openMobileNav = () => {
    // open
    if (!Utils.hasClass(mobileNav, "cb-mobile-navigation-show")) {
      Utils.attr(mobileTrigger.querySelector("a"), "aria-expanded", "true");
      const firstPanel = mobileNav.querySelectorAll(".cb-mobile-panel")[0];

      if (Utils.elemExists(firstPanel)) {
        // A11Y
        setLinkStatus(firstPanel, false);
        Utils.addClass(firstPanel, "cb-panel-show");
        setFocus(firstPanel);
      }

      const body = document.getElementsByTagName("body")[0];
      Utils.addClass(body, "cb-local-navigation-lock");

      // Custom event
      const event = new CustomEvent("apricot_localnavOpen");
      elem.dispatchEvent(event);
    }
  };

  const closeMobileNav = (e) => {
    if (e) e.preventDefault();

    Utils.attr(mobileTrigger.querySelector("a"), "aria-expanded", "false");
    backIDs = [];
    mobileNav.querySelectorAll(".cb-mobile-panel").forEach((panel) => {
      // A11Y
      setLinkStatus(panel, true);
      Utils.removeClass(panel, "cb-panel-show");
    });

    // Custom event
    const event = new CustomEvent("apricot_localnavClose");
    elem.dispatchEvent(event);

    const body = document.getElementsByTagName("body")[0];
    Utils.removeClass(body, "cb-local-navigation-lock");

    setTimeout(() => {
      mobileTrigger.querySelector("a").focus();
    }, 50);
  };

  const openChildPanel = (e) => {
    e.preventDefault();

    const link = e.currentTarget;
    const parent_id = Utils.attr(link, "id");
    const panel = mobileNav.querySelector(`.cb-mobile-panel[data-cb-parent="${parent_id}"]`);
    // add to back array
    backIDs.push(parent_id);
    // A11Y
    setLinkStatus(panel, false);
    Utils.addClass(panel, "cb-panel-show");
    setFocus(panel);
  };

  const closeCurrentPanel = (e) => {
    e.preventDefault();

    const link = e.currentTarget;
    const panel = Utils.getClosest(link, ".cb-mobile-panel");

    // A11Y
    setLinkStatus(panel, true);
    Utils.removeClass(panel, "cb-panel-show");

    const openPanel = mobileNav.querySelectorAll(".cb-panel-show");
    if (openPanel.length > 0) {
      setTimeout(() => {
        // go and set Focus to parent link
        let id = backIDs.length > 0 ? backIDs.slice(-1)[0] : null;
        backIDs.pop();

        setFocus(openPanel[openPanel.length - 1], id);
      }, 50);
    }
  };

  // set focus to first focusable element
  const setFocus = (panel, id) => {
    if (!Utils.elemExists(panel)) return;

    if (id) {
      const focusableNode = panel.querySelector(`#${id}`);
      if (focusableNode) {
        focusableNode.focus();
      }
    } else {
      const focusableNodes = panel.querySelectorAll(".cb-menu-link");

      if (focusableNodes.length) {
        focusableNodes[0].focus();
      }
    }
  };

  // keyboard navigation
  const keyBoardBehavior = (panel) => {
    if (!Utils.elemExists(panel)) return;

    panel.querySelectorAll("a").forEach((node) => {
      node.addEventListener("keydown", (e) => {
        const k = e.which || e.keyCode;
        //up/down/tab/shift
        if (!/(38|40|9|16)/.test(k)) {
          return;
        }

        e.preventDefault();
        e.stopPropagation();

        let index = 0;
        const tabbingBack = e.shiftKey;
        const items = panel.querySelectorAll(Utils.FOCUSABLE_ELEMENTS);

        Array.prototype.forEach.call(items, function (item, i) {
          if (node === item) {
            index = i;
          }
        });

        if (
          k === 9 &&
          ((k === 9 && tabbingBack && index === 0) || (!tabbingBack && index === items.length - 1))
        ) {
          //make sure menus are closed after tab away
          if (index === 0) {
            index = items.length - 1;
          } else if (index === items.length - 1) {
            index = 0;
          }
          const newActive = items.item(index);
          newActive.focus();
        } else {
          //up/down arrows
          if (k === 38 || (k === 9 && tabbingBack)) {
            index--; //up|shift+tab
          } else if (k === 40 || k === 9) {
            index++; //down|tab
          }

          if (index < 0 || index === items.length) {
            return;
          }

          const newActive = items.item(index);
          newActive.focus();
        }
      });
    });
  };

  // mobile
  // mode: false -> tabindex: 0, hidden: false
  // mode: true -> tabindex: -1, hidden: true
  const setLinkStatus = (panel, mode) => {
    panel.querySelectorAll("a").forEach((node) => {
      Utils.attr(node, "aria-hidden", `${mode}`);
      Utils.attr(node, "tabindex", mode ? "-1" : "0");
      if (
        Utils.attr(node, "aria-current") === "false" ||
        Utils.attr(node, "aria-current") === "page"
      ) {
        Utils.attr(node, "aria-current", mode ? "false" : "page");
      }
    });
  };

  // mode: false -> tabindex: 0, hidden: false
  // mode: true -> tabindex: -1, hidden: true
  const setDesktopLinkStatus = (mode) => {
    const panels = document.querySelector(".cb-local-navigation .cb-nav-items.hidden-xs-only");
    panels.querySelectorAll('a[data-cb-link="true"]').forEach((node) => {
      Utils.attr(node, "aria-hidden", `${mode}`);
      Utils.attr(node, "tabindex", mode ? "-1" : "0");
      if (Utils.attr(node, "aria-current")) {
        if (Utils.attr(node, "aria-current") !== "true") {
          Utils.attr(node, "aria-current", mode ? "false" : "page");
        }
      }
    });
  };

  const setDesktopLinks = () => {
    const panels = document.querySelector(".cb-local-navigation .cb-nav-items.hidden-xs-only");
    panels.querySelectorAll("a").forEach((node) => {
      if (!Utils.getClosest(node, "ul.cb-nested-menu")) {
        Utils.attr(node, "data-cb-link", "true");

        node.addEventListener("click", (e) => {
          // set selected
          if (data.selected) {
            if (!Utils.hasClass(node, "cb-dropdown-toggle")) {
              setSelectedLink(node, true);
            }
          }

          //  desktop callback
          if (data.callBackDesktop) {
            if (!Utils.hasClass(node, "cb-dropdown-toggle")) {
              data.callBackDesktop(e, node);
            }
          }
        });
      }
    });
  };

  const addMobileEvents = (panel) => {
    // close mobile for all close links
    panel.querySelectorAll(".cb-close-link").forEach((node) => {
      node.addEventListener("click", closeMobileNav);
    });

    // open nested panel (children)
    panel.querySelectorAll(".cb-nested-navigation a").forEach((child) => {
      child.addEventListener("click", openChildPanel);
    });

    if (data.mobileInteractionClose) {
      panel.querySelectorAll(".cb-single-navigation a").forEach((child) => {
        child.addEventListener("click", (e) => {
          closeMobileNav();
          if (data.callBack) {
            data.callBack(e, child);
          }
          if (data.callBackMobile) {
            data.callBackMobile(e, child);
          }

          if (data.selected) {
            setSelectedLink(child, false);
          }
        });
      });
    }

    // close nested panel (back)
    panel.querySelectorAll(".cb-back-link").forEach((node) => {
      node.addEventListener("click", closeCurrentPanel);
    });

    // A11Y
    keyBoardBehavior(panel);
  };

  // mode: 0, mobile
  // mode: 1, desktop
  const setSelectedLink = (a, mode) => {
    elem.querySelectorAll(".cb-selected").forEach((node) => {
      Utils.removeClass(node, "cb-selected");
      Utils.removeAttr(node, "aria-current");
    });

    // a11y
    Utils.attr(a, "aria-current", "page");
    Utils.addClass(a, "cb-selected");

    // match twin
    // reverse mode
    const twin = findTwinLink(a, !mode);
    if (twin) {
      Utils.attr(twin, "aria-current", "false");
      Utils.addClass(twin, "cb-selected");
    }
  };

  // mode: 0, mobile
  // mode: 1, desktop
  const findTwinLink = (a, mode) => {
    const href = Utils.attr(a, "href");
    const type = mode ? ".cb-desktop-navigation" : ".cb-mobile-navigation";
    const twin = elem.querySelector(`${type} a[href="${href}"]`);

    if (!Utils.getClosest(twin, "ul.cb-nested-menu")) {
      return twin;
    } else {
      return false;
    }
  };

  const trackMoreBehavior = () => {
    const triggerLink = mobileTrigger.querySelector("a");
    // only continue if desktop layout is active
    if (Utils.viewport().prefix === "xs") {
      // adjust mobile trigger A11Y
      if (triggerLink) {
        Utils.attr(triggerLink, "tabindex", "0");
        Utils.attr(triggerLink, "aria-hidden", "false");
      }

      setDesktopLinkStatus(true);
      return;
    }

    // adjust mobile trigger A11Y
    if (triggerLink) {
      Utils.attr(triggerLink, "tabindex", "-1");
      Utils.attr(triggerLink, "aria-hidden", "true");
    }

    setDesktopLinkStatus(false);

    // 48 is the gutter
    const dWidth = Utils.width(desktopNav) - 48;
    const nWith = dWidth - sWidth;

    let actualWidth = 0;
    let limit = 0;

    if (Utils.hasClass(more, "cb-hidden")) {
      limit = nWith;
    } else {
      limit = nWith - mWidth;
    }

    let linkArr = [];
    navItems.querySelectorAll(".cb-nav-link").forEach((link) => {
      const id = Utils.attr(link, "id");
      const lWidth = parseInt(Utils.attr(link, "data-cb-width"), 10);

      actualWidth += lWidth;

      if (actualWidth >= limit) {
        if (pluginStart) {
          linkArr.push(id);
        } else if (Utils.attr(link, "data-cb-more") !== "true") {
          linkArr.push(id);
        }
      } else {
        if (Utils.attr(link, "data-cb-more") === "true") {
          removeFromMore(id);
        }
      }
    });

    if (linkArr.length > 0) {
      linkArr.reverse();

      Array.prototype.forEach.call(linkArr, (id) => {
        addToMore(id);
      });
    }
    adjustMoreDisplay();
  };

  //  get all necessary width
  const getWidths = () => {
    const siteName = elem.querySelector(".cb-site-name");
    if (Utils.elemExists(siteName)) {
      sWidth = Utils.outerWidth(siteName) + 2;
    }

    mWidth = Utils.outerWidth(more) + 2;
    getItemsWidth();
  };

  const getItemsWidth = () => {
    navItems.querySelectorAll(".cb-nav-link").forEach((link) => {
      Utils.attr(link, "data-cb-width", Utils.width(link) + 2);
    });
  };

  const addToMore = (id) => {
    const item = elem.querySelector(`#${id}`);
    Utils.addClass(item, "cb-hidden");
    Utils.attr(item, "data-cb-more", "true");

    // adjust more
    if (item.querySelector(".cb-dropdown")) {
      addNestedMoreItem(id);
    } else {
      const mItem = item.querySelector("a").cloneNode(true);
      const moreId = `${id}_More`;
      const isSelected = Utils.hasClass(mItem, "cb-selected");
      Utils.removeAttr(mItem, "class");

      const icon = mItem.querySelector(".cb-glyph");
      if (Utils.elemExists(icon)) {
        Utils.remove(icon);
      }

      const li = document.createElement("LI");
      Utils.attr(li, "id", moreId);
      if (isSelected) {
        Utils.addClass(mItem, "cb-selected");
      }

      li.appendChild(mItem);

      if (firstIsHeader()) {
        const d = dividerTag(id);
        // appendToMore(d, id)
        appendToMore(d);
      }

      // add to more list
      appendToMore(li);
    }

    activateMoreDropdown();

    // more callback add
    if (data.callBackMore) {
      data.callBackMore(id, 1);
    }
  };

  const addNestedMoreItem = (id) => {
    const item = elem.querySelector(`#${id}`);
    const header = item.querySelector(".cb-dropdown-toggle span");

    let title = "";
    if (Utils.elemExists(header)) {
      title = header.innerText;
    }

    // add divider
    if (!firstIsDivider()) {
      const d = dividerTag(id);
      appendToMore(d);
    }

    // add dropdown items
    // let's do it old school, safer for IE
    const liArr = [];
    item.querySelectorAll(".cb-dropdown-menu ul li.cb-nav-link-second").forEach((item) => {
      const li = item.cloneNode(true);

      li.querySelectorAll(".cb-nested-menu").forEach((item) => {
        Utils.remove(item);
      });

      Utils.addClass(li, id);
      liArr.push(li);
    });

    liArr.reverse();
    Array.prototype.forEach.call(liArr, (li) => {
      appendToMore(li);
    });

    // add header
    const liH = document.createElement("LI");
    Utils.addClass(liH, "cb-dropdown-header");
    Utils.addClass(liH, id);
    Utils.attr(liH, "role", "presentation");

    const h3 = document.createElement("H3");
    h3.innerHTML = title;
    liH.appendChild(h3);

    appendToMore(liH);
  };

  const appendToMore = (item) => {
    // if we already have something
    // prepend
    if (moreMenu.querySelectorAll("li").length > 0) {
      const firstChild = moreMenu.querySelectorAll("li")[0];

      Utils.insertBefore(firstChild, item);
    } else {
      if (!Utils.hasClass(item, "cb-divider")) {
        moreMenu.appendChild(item);
      }
    }
  };

  const dividerTag = (id) => {
    const divider = document.createElement("LI");
    Utils.attr(divider, "role", "separator");
    Utils.addClass(divider, "cb-divider");
    Utils.addClass(divider, id);

    return divider;
  };

  const firstIsDivider = () => {
    let mode = false;

    if (moreMenu.querySelectorAll("li").length > 0) {
      const firstChild = moreMenu.querySelectorAll("li")[0];

      mode = Utils.hasClass(firstChild, "cb-divider");
    }
    return mode;
  };

  const firstIsHeader = () => {
    let mode = false;

    if (moreMenu.querySelectorAll("li").length > 0) {
      const firstChild = moreMenu.querySelectorAll("li")[0];

      mode = Utils.hasClass(firstChild, "cb-dropdown-header");
    }
    return mode;
  };

  const removeFromMore = (id) => {
    const item = elem.querySelector(`#${id}`);
    Utils.removeAttr(item, "data-cb-more");
    Utils.removeClass(item, "cb-hidden");

    // adjust more
    if (Utils.elemExists(item.querySelector(".cb-dropdown"))) {
      removeNestedMoreItem(id);
    } else {
      const moreId = `${id}_More`;
      const mItem = more.querySelector(`#${moreId}`);

      if (Utils.elemExists(mItem)) {
        Utils.remove(mItem);
      }
      removeNestedMoreItem(id);
    }

    // more callback remove
    if (data.callBackMore) {
      data.callBackMore(id, 0);
    }
  };

  const removeNestedMoreItem = (id) => {
    moreMenu.querySelectorAll(`.${id}`).forEach((li) => {
      Utils.remove(li);
    });
  };

  const adjustMoreDisplay = () => {
    const showMore = more.querySelectorAll(".cb-dropdown-menu li").length > 0;
    if (showMore) {
      Utils.removeClass(more, "cb-hidden");
    } else {
      Utils.addClass(more, "cb-hidden");
    }
  };

  // generate menu list
  const listNavItems = async () => {
    let navListItems = [];
    let parentId = 1;

    navItems.querySelectorAll(".cb-nav-link").forEach((item) => {
      if (!Utils.hasClass(item, "cb-more")) {
        const nav = item.firstElementChild;
        const type = nav.tagName.toLowerCase();

        // we have nested
        if (type === "div" || Utils.hasClass(nav, "cb-dropdown")) {
          const obj = {};

          // build a base node
          const link = document.createElement("A");
          Utils.addClass(link, "cb-menu-link");

          const span = document.createElement("SPAN");
          Utils.attr(link, "href", "#");

          const title = nav.querySelector(".cb-dropdown-toggle span")
            ? nav.querySelector(".cb-dropdown-toggle span").innerText
            : "Error";
          span.innerText = title;

          link.appendChild(span);
          obj.elem = link;
          obj.title = title;
          obj.node_id = Utils.attr(link, "id")
            ? Utils.attr(link, "id")
            : Utils.uniqueID(5, "apricot_");
          obj.parent_id = null;
          obj.parent = parentId;

          const nElem = nav.querySelectorAll(".cb-dropdown-menu ul")[0];
          if (Utils.elemExists(nElem)) {
            // start deep diving
            const list = getChildrenList(nElem, parentId, obj.node_id);
            obj.items = list;
            if (list.length > 0) {
              parentId += parentCount + 1;
              parentCount = 0;
            }
          } else {
            obj.items = [];
          }
          navListItems.push(obj);
        } else {
          // simple
          const mNav = nav.cloneNode(true);
          // make sure we don't have duplicate ID's

          let id = Utils.attr(mNav, "id");
          id = id ? `${id}_mobile` : Utils.uniqueID(5, "apricot_");
          Utils.attr(mNav, "id", id);

          const obj = {};
          obj.elem = mNav;
          obj.title = mNav.innerText;
          obj.items = [];
          obj.node_id = id;
          obj.parent_id = null;
          navListItems.push(obj);
        }
      }
    });

    buildMobilePanels(navListItems);
  };

  // start building mobile panels
  const buildMobilePanels = (panels, title) => {
    panelCount += 1;

    // build a panel
    buildPanel(panels, title);

    Array.prototype.forEach.call(panels, (panel) => {
      if (panel.items.length > 0) {
        buildMobilePanels(panel.items, panel.title);
      }
    });
  };

  const buildPanel = (items, title) => {
    // Build Mobile panels
    const panel = document.createElement("DIV");
    Utils.addClass(panel, "cb-mobile-panel");
    Utils.attr(panel, "data-cb-panel", panelCount);
    Utils.attr(panel, "data-cb-parent", items[0].parent_id);

    const panelNav = document.createElement("DIV");
    Utils.addClass(panelNav, "cb-panel-navigation");

    // back link
    if (panelCount > 1) {
      const back = document.createElement("A");
      Utils.addClass(back, "cb-back-link");
      Utils.attr(back, "href", "#");
      Utils.attr(back, "aria-hidden", "true");
      Utils.attr(back, "tabindex", "-1");

      const glyphBack = document.createElement("SPAN");
      Utils.addClass(glyphBack, ["cb-glyph", "cb-left", "cb-margin-right-8"]);
      Utils.attr(glyphBack, "aria-hidden", "true");

      const textBack = document.createElement("SPAN");
      textBack.innerText = "Back";

      back.appendChild(glyphBack);
      back.appendChild(textBack);
      panelNav.appendChild(back);
    }

    // close link
    const close = document.createElement("A");
    Utils.addClass(close, "cb-close-link");
    Utils.attr(close, "href", "#");
    Utils.attr(close, "aria-hidden", "true");
    Utils.attr(close, "tabindex", "-1");

    const glyphClose = document.createElement("SPAN");
    Utils.addClass(glyphClose, ["cb-glyph", "cb-x-mark"]);
    Utils.attr(glyphClose, "aria-hidden", "true");

    const srClose = document.createElement("SPAN");
    Utils.addClass(srClose, "sr-only");
    srClose.innerText = "Close Navigation Panels";

    close.appendChild(glyphClose);
    close.appendChild(srClose);
    panelNav.appendChild(close);

    // Nav menu
    const nav = document.createElement("NAV");
    Utils.addClass(nav, ["cb-menu-list", "cb-menu-list-vertical"]);
    if (title) {
      Utils.attr(nav, "aria-label", `${data.mobileSubLabel} ${title}`);
    } else {
      Utils.attr(nav, "aria-label", `${desktopAriaLabel} ${data.mobileMainLabel}`);
    }

    const ul = document.createElement("UL");
    nav.appendChild(ul);

    // add number of sub-panels, per panel
    // let subPanels = (items.children) ? items.children : 0

    // start looping
    Array.prototype.forEach.call(items, (obj) => {
      const li = document.createElement("LI");
      const link = obj.elem;

      // A11Y
      Utils.attr(link, "aria-hidden", "true");
      Utils.attr(link, "tabindex", "-1");

      // add nested requirements
      if (obj.items.length > 0) {
        const spanLabel = link.querySelector("span");
        let spanLabelId = Utils.uniqueID(5, "apricot_");
        if (Utils.elemExists(spanLabel)) {
          Utils.attr(spanLabel, "id", spanLabelId);
        } else {
          spanLabelId = "";
        }

        Utils.addClass(li, "cb-nested-navigation");

        // we have to keep the first panel in mind (x + 1)
        Utils.attr(link, "data-cb-child", obj.parent + 1);
        Utils.attr(link, "id", obj.node_id);

        const glyphNext = document.createElement("SPAN");
        Utils.addClass(glyphNext, ["cb-glyph", "cb-right"]);
        Utils.attr(glyphNext, "aria-hidden", "true");

        link.appendChild(glyphNext);

        const spanInfo = document.createElement("SPAN");
        Utils.addClass(spanInfo, "sr-only");
        spanInfo.innerText = "  opens sub navigation group";
        let spanInfoId = Utils.uniqueID(5, "apricot_");
        Utils.attr(spanInfo, "id", spanInfoId);

        link.appendChild(spanInfo);
        Utils.attr(link, "aria-labelledby", `${spanLabelId} ${spanInfoId}`);
      } else {
        Utils.addClass(li, "cb-single-navigation");
      }

      li.appendChild(link);
      ul.appendChild(li);
    });

    // add to main container
    panel.appendChild(panelNav);
    panel.appendChild(nav);
    mobileNav.appendChild(panel);

    // add all required events
    addMobileEvents(panel);
  };

  const getChildrenList = (items, parentId, parent_id) => {
    const navList = [];

    Array.prototype.forEach.call(items.children, (item) => {
      // we only want li tags
      if (item.tagName.toLowerCase() === "li") {
        const obj = {};
        const a = item.firstElementChild;

        const href = Utils.attr(a, "href") ? Utils.attr(a, "href") : "#";
        let id = Utils.attr(a, "id");
        id = id ? `${id}_mobile` : Utils.uniqueID(5, "apricot_");

        const link = document.createElement("A");
        Utils.addClass(link, "cb-menu-link");
        Utils.attr(link, "href", href);
        Utils.attr(link, "id", id);

        const span = document.createElement("SPAN");
        span.innerText = a.innerText;

        link.appendChild(span);

        obj.title = a.innerText;
        obj.elem = link;
        obj.node_id = id;
        obj.parent_id = parent_id;
        // obj.child = parentId
        Array.prototype.forEach.call(item.children, (child) => {
          if (Utils.hasClass(child, "cb-nested-menu")) {
            const list = getChildrenList(child, parentId + 1, id);
            obj.items = list;
            if (list.length > 0) {
              obj.parent = parentId + 1;
              parentCount += 1;
            }
          } else {
            obj.items = [];
          }
        });

        navList.push(obj);
      }
    });

    return navList;
  };

  const closePanelsOnResize = (e) => {
    const data = e.data;

    if (data.prefix !== "xs") {
      closeMobileNav();
      setDesktopLinkStatus(false);
    } else {
      setDesktopLinkStatus(true);
    }
  };

  // Remove
  const destroy = () => {
    if (elem.localNav === "cb") {
      elem.localNav = null;

      // remove mobile panels
      mobileNav.innerHTML = "";
      document.removeEventListener("apricot_breakpointChange", closePanelsOnResize);
      window.removeEventListener("resize", trackMoreBehavior);

      if (data.sticky) {
        window.removeEventListener("scroll", scrollFunction);
      }

      if (data.mobileEscClose) {
        document.removeEventListener("keydown", escClose);
      }
    }
  };

  if (elem.localNav !== "cb") {
    init();
  }

  return {
    destroy: destroy,
  };
};

export default LocalNavigation;
