/* ========================================================================
 * Apricot's Modals
 * ========================================================================
 *
 * This plugin is written based on Micromodal.js
 * https://github.com/Ghosh/micromodal
 * ======================================================================== */

// SCSS
import "../scss/includes/apricot-base.scss";
import "../scss/includes/modal.scss";
import "../scss/includes/button.scss";

// javaScript
import Utils from "./CBUtils";

/**
 * Modal
 *
 * @class
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Element} data.targetElem
 * @param {String} data.targetModal
 * @param {Element} data.focusElem
 * @param {Array} data.triggers
 * @param {Function} data.onShow
 * @param {Function} data.onClose
 * @param {Boolean} data.videoModal
 * @param {Boolean} data.disableFocus
 * @param {Boolean} data.disableScroll
 * @param {Boolean} data.disableHeightAdjustment
 * @param {Boolean} data.disableHeightAdjustmentAria
 * @param {Boolean} data.escClose
 * @param {Boolean} data.awaitOpenAnimation
 * @param {Boolean} data.controlled
 * @param {Boolean} data.analytics
 * @param {String} data.analyticsTitle
 * @param {Boolean} data.analyticsOnClose
 * @returns {{init: Function}}
 * @returns {{show: Function}}
 * @returns {{close: Function}}
 * @returns {{adjustHeight: Function}}
 */
const Modal = (() => {
  class Modal {
    constructor({
      elem,
      targetElem,
      targetModal,
      focusElem,
      triggers = [],
      onShow = () => {},
      onClose = () => {},
      videoModal = false,
      openTrigger = "data-cb-modal-trigger",
      closeTrigger = "data-cb-modal-close",
      disableFocus = false,
      disableScroll = false,
      disableHeightAdjustment = false,
      disableHeightAdjustmentAria = false,
      escClose = true,
      awaitOpenAnimation = true,
      awaitCloseAnimation = true,
      controlled = false,
      analytics = false,
      analyticsTitle,
      analyticsOnClose = false,
    }) {
      // Save a reference of the modal
      this.modal = document.getElementById(targetModal);
      this.shown = false;

      // Exit if modal does not exist
      if (!Utils.elemExists(this.modal)) return;

      // Modal sections
      this.modalContainer = this.modal.querySelector(".cb-modal-container");
      this.modalHeader = this.modal.querySelector(".cb-modal-header");
      this.modalContent = this.modal.querySelector(".cb-modal-content");
      this.modalFooter = this.modal.querySelector(".cb-modal-footer");

      if (this.modalContent) {
        if (this.modalContent.querySelector(".cb-dialog")) {
          this.adjustableContent = this.modalContent.querySelector(".cb-dialog-content");
        } else if (this.modalContent.querySelector(".cb-notification")) {
          this.adjustableContent = this.modalContent.querySelector(".cb-notification-content");
        } else {
          this.adjustableContent = this.modalContent;
        }
      } else {
        console.warn("Apricot Modal: Seems like your modal has no content block.");
      }
      // needed for style adjustment
      if (this.modalHeader && this.modalHeader.querySelector(".cb-btn-close")) {
        Utils.addClass(this.modalHeader, "cb-modal-has-close");
      }

      // Save a reference to the passed config

      this.config = {
        disableScroll,
        disableHeightAdjustment,
        disableHeightAdjustmentAria,
        openTrigger,
        closeTrigger,
        controlled,
        analytics,
        analyticsTitle,
        analyticsOnClose,
        onShow,
        onClose,
        awaitOpenAnimation,
        awaitCloseAnimation,
        videoModal,
        disableFocus,
        escClose,
        focusElem,
      };

      // Register click events only if prebinding eventListeners
      if (triggers.length > 0) {
        this.registerTriggers(...triggers);
      }

      if (this.config.videoModal) {
        this.videoIframe = this.modal.querySelector("iframe");
        if (this.videoIframe) {
          this.videoSrc = Utils.attr(this.videoIframe, "data-cb-src");
        }
      }

      // activate analytics tracker
      if (this.config.analytics) {
        const title = this.modal.querySelector(".cb-modal-title");
        const dialogTitle = this.modal.querySelector(".cb-notification-title");
        const trackerTitle = this.config.analyticsTitle
          ? this.config.analyticsTitle
          : Utils.elemExists(title)
          ? title.textContent || title.innerText
          : Utils.elemExists(dialogTitle)
          ? dialogTitle.textContent || dialogTitle.innerText
          : `missing title - ${targetModal}`;
        this.modalContainer.setAttribute("data-cbtrack-modal", trackerTitle);
        this.config.analyticsTitle = trackerTitle;
      }

      // Resize
      this.registerResize();

      // prebind functions for event listeners
      this.onClick = this.onClick.bind(this);
      this.onKeydown = this.onKeydown.bind(this);
    }

    /**
     * Loops through all openTriggers and binds click event
     * @param  {array} triggers [Array of node elements]
     * @return {void}
     */
    registerTriggers(...triggers) {
      triggers.filter(Boolean).forEach((trigger) => {
        trigger.addEventListener("click", (e) => {
          e.preventDefault();

          this.showModal();
        });
      });
    }

    registerResize() {
      const modal = this;
      window.addEventListener("resize", (e) => {
        modal.calculateHeight();
      });
    }

    calculateHeight() {
      if (this.config.disableHeightAdjustment) return;
      // no content
      if (!this.adjustableContent) return;
      // empty content
      if (!this.adjustableContent.hasChildNodes()) return;
      if (!this.shown) return;

      this.resetHeight();

      // Only calculate when the modal is open
      if (this.config.videoModal) {
        let pageHeight = window.innerHeight;
        let pageWidth = window.innerWidth;
        let height = parseInt(pageHeight, 10) - 96;
        let width = parseInt(pageWidth, 10) - 96;
        let w = 0;
        let h = 0;

        if (pageWidth < pageHeight) {
          w = (width * 98) / 100;
          h = Math.round((w * 9) / 16);
        } else {
          h = (height * 98) / 100;
          w = Math.round((h * 16) / 9);
        }

        this.modalContainer.style.width = w + "px";
        this.modalContainer.style.height = h + "px";
      } else if (this.shown) {
        let ch = Utils.height(this.modalContainer);
        let mh = Utils.height(this.adjustableContent);
        let hh = 0;
        let fh = 0;
        let newHeight = 0;

        if (this.modalHeader) hh = Utils.outerHeight(this.modalHeader);
        if (this.modalFooter) {
          fh = Utils.outerHeight(this.modalFooter);
        } else {
          // GS-9130
          // fh = 24;
          fh = 0;
        }
        let mpAdjust = 24 * 3;
        let header = null;

        if (this.modalContent.querySelector(".cb-dialog")) {
          header = this.modalContent.querySelector(".cb-dialog-header");
        } else if (this.modalContent.querySelector(".cb-notification")) {
          header = this.modalContent.querySelector(".cb-notification-header");
        }

        if (
          this.modalContent.querySelector(".cb-dialog") ||
          this.modalContent.querySelector(".cb-notification")
        ) {
          fh = 0;
          mpAdjust = 24 * 2 + 12;
          hh = Utils.outerHeight(header);
        }

        newHeight = ch - mpAdjust - hh - fh;

        if (mh > newHeight) {
          this.adjustableContent.style.overflowY = "auto";
          this.adjustableContent.style.height = newHeight + "px";
          if (!this.config.disableHeightAdjustmentAria) {
            Utils.attr(this.adjustableContent, "tabindex", "0");
            Utils.attr(this.adjustableContent, "role", "region");
            Utils.attr(this.adjustableContent, "aria-label", "scrollable content");
          }
        } else {
          this.resetHeight();
        }
      }
    }

    resetHeight() {
      if (this.config.videoModal) {
        if (!this.modalContainer) return;
        this.modalContainer.style.overflowY = "hidden";
        this.modalContainer.style.height = "auto";
      } else {
        if (!this.adjustableContent) return;
        if (!this.adjustableContent.hasChildNodes()) return;

        this.adjustableContent.style.overflowY = "hidden";
        this.adjustableContent.style.height = "auto";
        if (!this.config.disableHeightAdjustmentAria) {
          Utils.removeAttr(this.adjustableContent, "tabindex");
          Utils.removeAttr(this.adjustableContent, "role");
          Utils.removeAttr(this.adjustableContent, "aria-label");
        }
      }
    }

    showModal() {
      this.activeElement = document.activeElement;
      this.modal.setAttribute("aria-hidden", "false");

      if (this.config.videoModal) {
        if (this.videoIframe) {
          this.videoIframe.src = this.videoSrc;
        }
      }

      this.shown = true;
      this.modal.classList.add("cb-open");

      if (this.config.awaitOpenAnimation) {
        const event = new CustomEvent("apricot_modalShow_start");
        this.activeElement.dispatchEvent(event);
        this.modal.dispatchEvent(event);

        const self = this;
        // animationend event is fired when a CSS Animation has completed
        this.modal.addEventListener(
          "animationend",
          function handler() {
            self.modal.classList.add("cb-open");
            self.calculateHeight();
            self.setFocusToFirstNode();
            self.scrollBehaviour("disable");
            self.addEventListeners();
            self.config.onShow(this.modal);

            self.modal.removeEventListener("animationend", handler, false);

            // Trigger Show Event
            const event = new CustomEvent("apricot_modalShow");
            self.activeElement.dispatchEvent(event);
            self.modal.dispatchEvent(event);
          },
          false
        );
      } else {
        this.modal.classList.add("cb-open");

        this.calculateHeight();
        this.setFocusToFirstNode();
        this.scrollBehaviour("disable");
        this.addEventListeners();
        this.config.onShow(this.modal);
        // Trigger Show Event
        const event = new CustomEvent("apricot_modalShow");
        this.activeElement.dispatchEvent(event);
        this.modal.dispatchEvent(event);
      }

      // trigger analytics tracker
      if (this.config.analytics) {
        this.trackAnalytics(true);
      }

      const body = document.getElementsByTagName("body")[0];
      Utils.addClass(body, "cb-modal-open");
    }

    closeModal(options = {}) {
      // for react only
      // "controlled" components just want to be notified that they should close
      if (this.config.controlled && !options.force) {
        this.config.onClose();

        return;
      }

      if (!this.modal) return;

      this.modal.setAttribute("aria-hidden", "true");

      if (this.config.videoModal) {
        if (this.videoIframe) {
          this.videoIframe.src = "";
        }
      }

      this.shown = false;
      this.removeEventListeners();
      this.scrollBehaviour("enable");
      // set focus to active elem, A11Y
      if (this.activeElement) {
        this.activeElement.focus();
      }

      // check if close method has a onClose param
      if (options.onClose) {
        options.onClose(this.modal);
      } else {
        this.config.onClose(this.modal);
      }

      if (this.config.awaitCloseAnimation) {
        const event = new CustomEvent("apricot_modalClose_start");
        this.activeElement.dispatchEvent(event);
        this.modal.dispatchEvent(event);

        const self = this;

        // animationend event is fired when a CSS Animation has completed
        this.modal.addEventListener(
          "animationend",
          function handler() {
            self.modal.classList.remove("cb-open");
            self.modal.removeEventListener("animationend", handler, false);
            // Trigger Close Event
            const event = new CustomEvent("apricot_modalClose");
            self.activeElement.dispatchEvent(event);
            self.modal.dispatchEvent(event);
          },
          false
        );
      } else {
        this.modal.classList.remove("cb-open");

        // Trigger Close Event
        const event = new CustomEvent("apricot_modalClose");
        this.activeElement.dispatchEvent(event);
        this.modal.dispatchEvent(event);
      }

      // trigger analytics tracker
      if (this.config.analytics && this.config.analyticsOnClose) {
        this.trackAnalytics(false);
      }

      const body = document.getElementsByTagName("body")[0];
      Utils.removeClass(body, "cb-modal-open");

      this.resetHeight();
    }

    closeModalById(targetModal, onClose) {
      this.modal = document.getElementById(targetModal);

      if (this.modal) {
        this.closeModal({
          force: true,
          onClose: onClose,
        });
      }
    }

    adjustHeightById(targetModal) {
      this.modal = document.getElementById(targetModal);

      if (this.modal && Utils.hasClass(this.modal, "cb-open")) this.calculateHeight();
    }

    scrollBehaviour(toggle) {
      if (!this.config.disableScroll) return;
      const body = document.querySelector("body");
      switch (toggle) {
        case "enable":
          body.style = {
            ...body.style,
            ...{
              overflow: "",
              height: "",
            },
          };
          break;
        case "disable":
          body.style = {
            ...body.style,
            ...{
              overflow: "hidden",
              height: "100vh",
            },
          };
          break;
        default:
      }
    }

    addEventListeners() {
      this.modal.addEventListener("touchstart", this.onClick, { passive: true });
      this.modal.addEventListener("click", this.onClick);
      document.addEventListener("keydown", this.onKeydown);
    }

    removeEventListeners() {
      this.modal.removeEventListener("touchstart", this.onClick, { passive: true });
      this.modal.removeEventListener("click", this.onClick);
      document.removeEventListener("keydown", this.onKeydown);
    }

    onClick(event) {
      let elem = event.target;
      const parent = Utils.parent(elem);

      if (parent.tagName === "BUTTON") {
        elem = parent;
      }

      if (elem.hasAttribute(this.config.closeTrigger)) {
        this.closeModal();
        event.preventDefault();
      }
    }

    onKeydown(event) {
      const body = document.getElementsByTagName("body")[0];
      // If toast esc is not in place
      if (event.keyCode === 27 && this.config.escClose && !Utils.attr(body, "data-cb-esc")) {
        this.closeModal(event);
      }
      if (!Utils.hasClass(this.modal, "cb-photo-gallery-modal")) {
        if (event.keyCode === 9) this.maintainFocus(event);
      }
    }

    getFocusableNodes() {
      const nodes = this.modal.querySelectorAll(Utils.FOCUSABLE_ELEMENTS);
      return Array(...nodes);
    }

    setFocusToFirstNode() {
      if (this.config.disableFocus) return;

      const dialog = this.modal.querySelector('div[role="dialog"]');
      if (dialog) {
        // set focus to specific element when requested
        if (Utils.elemExists(this.config.focusElem)) {
          this.config.focusElem.focus();
        } else {
          Utils.attr(dialog, "tabIndex", "0");
          dialog.focus();
        }
      } else {
        const focusableNodes = this.getFocusableNodes();
        if (focusableNodes.length) focusableNodes[0].focus();
      }
    }

    maintainFocus(event) {
      const focusableNodes = this.getFocusableNodes();

      // if disableFocus is true
      if (!this.modal.contains(document.activeElement)) {
        focusableNodes[0].focus();
      } else {
        const focusedItemIndex = focusableNodes.indexOf(document.activeElement);

        if (event.shiftKey && focusedItemIndex === 0) {
          focusableNodes[focusableNodes.length - 1].focus();
          event.preventDefault();
        }

        if (!event.shiftKey && focusedItemIndex === focusableNodes.length - 1) {
          focusableNodes[0].focus();
          event.preventDefault();
        }
      }
    }

    // true: onShow
    // false: onClose
    trackAnalytics(mode) {
      const eventName = mode ? "cbTrack-modalOpen" : "cbTrack-modalClose";
      document.dispatchEvent(
        new CustomEvent(eventName, {
          bubbles: true,
          detail: {
            modalName: this.config.analyticsTitle,
            modalEl: this.modalContainer,
          },
        })
      );
    }
  }

  /**
   * Modal prototype ends.
   * Here on code is responsible for detecting and
   * auto binding event handlers on modal triggers
   */

  // Keep a reference to the opened modal
  let activeModal = null;

  /**
   * Generates an associative array of modals and it's
   * respective triggers
   * @param  {array} triggers     An array of all triggers
   * @param  {string} triggerAttr The data-attribute which triggers the module
   * @return {array}
   */
  const generateTriggerMap = (triggers, triggerAttr) => {
    const triggerMap = [];

    triggers.forEach((trigger) => {
      const targetModal = trigger.attributes[triggerAttr].value;
      if (triggerMap[targetModal] === undefined) triggerMap[targetModal] = [];
      triggerMap[targetModal].push(trigger);
    });

    return triggerMap;
  };

  /**
   * Validates whether a modal of the given id exists
   * in the DOM
   * @param  {number} id  The id of the modal
   * @return {boolean}
   */
  const validateModalPresence = (id) => {
    if (!document.getElementById(id)) {
      console.warn(
        `Apricot Modal: Seems like you have missed %c'${id}'`,
        "background-color: #f8f9fa;color: #50596c;font-weight: bold;",
        "ID somewhere in your code."
      );
      return false;
    }
  };

  /**
   * Binds click handlers to all modal triggers
   * @param  {object} config [description]
   * @return void
   */
  const init = (config) => {
    // Create an config object with default openTrigger
    const options = {
      ...{
        openTrigger: "data-cb-modal-trigger",
      },
      ...config,
    };

    let triggers = [];
    let triggerMap = [];

    // Check if it's a single instant or by trigger-map
    if (Utils.elemExists(options.elem)) {
      triggers.push(options.elem);
      if (options.targetElem !== undefined) triggerMap[options.targetElem] = triggers;
    } else {
      // Collects all the nodes with the trigger
      triggers = [...document.querySelectorAll(`[${options.openTrigger}]`)];

      // Makes a mappings of modals with their trigger nodes
      triggerMap = generateTriggerMap(triggers, options.openTrigger);
    }

    // Checks if modals and triggers exist in dom
    // if (validateArgs(triggers, triggerMap) === false) return false

    // For every target modal creates a new instance
    for (var key in triggerMap) {
      let value = triggerMap[key];
      options.targetModal = key;
      options.triggers = [...value];

      // new Modal(options)
      activeModal = new Modal(options);
    }
  };

  /**
   * Shows a particular modal
   * @param {Object} data
   * @param {string} data.targetModal [The id of the modal to display]
   * @param {Function} data.onShow
   * @param {Function} data.onClose
   * @param {Boolean} data.disableScroll
   * @param {Boolean} data.disableHeightAdjustment
   * @param {Boolean} data.disableHeightAdjustmentAria
   * @param {Boolean} data.disableFocus
   * @param {Boolean} data.awaitCloseAnimation
   * @param {Boolean} data.analytics
   * @param {String} data.analyticsTitle
   * @param {Boolean} data.analyticsOnClose
   * @return {void}
   */

  const show = (data) => {
    const options = data || {};

    // Checks if modals and triggers exist in dom
    if (validateModalPresence(options.targetModal) === false) return false;

    // clear events in case previous modal wasn't close
    if (activeModal) activeModal.removeEventListeners();

    // stores reference to active modal
    activeModal = new Modal(options);
    activeModal.showModal();
  };

  /**
   * Closes the active or particular modal
   * @param {Object} data
   * @param  {string} data.targetModal [The id of the modal to close]
   * @param  {Function} data.onClose [callback function to call when modal closes]
   * @return {void}
   */
  const close = (data) => {
    let options = {};

    if (typeof data === "object") {
      options = data || {};
    } else {
      options.targetModal = data;
      options.onClose = null;
    }

    options.targetModal
      ? activeModal && activeModal.closeModalById(options.targetModal, options.onClose)
      : activeModal &&
        activeModal.closeModal({
          force: true,
          onClose: options.onClose,
        });
  };

  /**
   * Adjust Height for active modal
   * @param  {string} targetModal [The id of the modal]
   * @return {void}
   */
  const adjustHeight = (targetModal) => {
    targetModal
      ? activeModal && activeModal.adjustHeightById(targetModal)
      : activeModal && activeModal.calculateHeight();
  };

  return {
    init,
    show,
    close,
    adjustHeight,
  };
})();

export default Modal;
