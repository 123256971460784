/* ========================================================================
 * Apricot's Dialogs
 * Dialog component has been renamed to Notification as of June 2021
 * ======================================================================== */

// SCSS
import "../scss/includes/apricot-base.scss";
import "../scss/includes/dialog.scss";
import "../scss/includes/glyph.scss";
import "../scss/includes/button.scss";

// javaScript
import Utils from "./CBUtils";

/**
 * Dismissible Dialog
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Boolean} data.remove
 * @param {Boolean} data.animation
 * @returns {{destroy: Function}}
 */
const Dismissible = (data = {}) => {
  const defaultData = {
    elem: null,
    remove: false,
    animation: true,
  };
  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let close = null;

  if (!Utils.elemExists(elem)) return null;

  const init = () => {
    elem.dismissibleDialog = "cb";
    close = elem.querySelector("[data-cb-dialog-close]");
    if (!Utils.elemExists(close)) return null;

    close.addEventListener("click", hideDialog);
    if (useAnimation()) {
      window.addEventListener("resize", adjustDialogHeight);
    }
  };

  const adjustDialogHeight = () => {
    Utils.removeAttr(elem, "data-cb-height");
  };

  const hideDialog = (e) => {
    if (e) e.preventDefault();

    const height = elem.offsetHeight;

    elem.style.height = height + "px";
    Utils.attr(elem, "data-cb-height", height);

    setTimeout(() => {
      elem.style.removeProperty("height");
      Utils.addClass(elem, "cb-dialog-hide");
      Utils.attr(elem, "aria-hidden", "true");
      const event = new CustomEvent("apricot_dialogHidden");
      elem.dispatchEvent(event);

      if (data.remove) {
        setTimeout(() => {
          Utils.remove(elem);
        }, 60);
      } else {
        // a11y
        Array.from(elem.querySelectorAll(Utils.FOCUSABLE_ELEMENTS)).forEach((node) => {
          Utils.attr(node, "tabIndex", "-1");
        });
      }
    }, 50);
  };

  const showDialog = () => {
    if (isNaN(Utils.attr(elem, "data-cb-height"))) {
      elem.style.height = "auto";
    } else {
      elem.style.height = Utils.attr(elem, "data-cb-height") + "px";
    }

    setTimeout(() => {
      Utils.removeClass(elem, "cb-dialog-hide");
      Utils.removeAttr(elem, "aria-hidden");
      elem.style.removeProperty("height");

      const event = new CustomEvent("apricot_dialogShown");
      elem.dispatchEvent(event);
      // a11y
      Array.from(elem.querySelectorAll(Utils.FOCUSABLE_ELEMENTS)).forEach((node) => {
        Utils.attr(node, "tabIndex", "0");
      });
    }, 50);
  };

  const useAnimation = () => {
    return Utils.reduceMotionChanged() ? false : data.animation;
  };

  const destroy = () => {
    if (elem.dismissibleDialog === "cb") {
      elem.dismissibleDialog = null;
      close.removeEventListener("click", hideDialog);

      if (useAnimation()) {
        window.removeEventListener("resize", adjustDialogHeight);
      }
    }
  };

  if (elem.dismissibleDialog !== "cb") {
    init();
  }

  return {
    hideDialog: hideDialog,
    showDialog: showDialog,
    destroy: destroy,
  };
};

const CBDialog = {
  Dismissible,
};

export default CBDialog;

window.cb = window.cb || {};
window.cb.apricot = window.cb.apricot || {};
window.cb.apricot.CBDialog = CBDialog;
